import { useState } from 'react'

export const useLocalStorage = <T>(
	keyName: string,
	initialValue?: T,
): [T, React.Dispatch<React.SetStateAction<T>>] => {

	const [storedValue, setStoredValue] = useState(() => {
		try {
			const item = window.localStorage.getItem(keyName)
			return item ? JSON.parse(item) : initialValue
		} catch (error) {
			console.error(error)
			return initialValue
		}
	})

	// eslint-disable-next-line no-unused-vars
	const setValue = (value: T | ((val: T) => T)) => {
		try {
			const valueToStore = value instanceof Function ? value(storedValue) : value
			setStoredValue(valueToStore)
			window.localStorage.setItem(keyName, JSON.stringify(valueToStore))
		} catch (error) {
			console.error(error)
		}
	}

	return [storedValue, setValue]
}
