interface VideoPlayerProps {
	videoSrc: string
	title: string
}

export const VideoPlayer = ({ videoSrc, title }: VideoPlayerProps) => {
	const srcString = `https://www.youtube.com/embed/${videoSrc}?rel=0&autoplay=1&origin=https://timechaincalendar.com`
	return (
		<iframe
			width="100%"
			height="100%"
			src={srcString}
			title={title}
			frameBorder="0"
			allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
			allowFullScreen
		/>
	)
}