import { useEffect, useState } from 'react'
import { Flex, Text, type ResponsiveValue } from '@chakra-ui/react'
import { useRecoilValue } from 'recoil'

import { BlockScrub } from '../calendar-scrub'
import {
	confModeState,
	confLowerMessageState,
	circlesWrapWidthState,
	circlesBreakpointState,
} from '../../../state'
import { getBreakpointValue } from '../../../utils'
import { BITCOIN_ORANGE } from '../../../constants'
import { cornersDataFontSize } from '../calendar/data-components/dataComponents.constants'

interface TCCalendarControlsProps {
	// eslint-disable-next-line no-unused-vars
	handleScrubChange: (x: number) => void
	// eslint-disable-next-line no-unused-vars
	handleScrubChangeEnd: (x: number) => void
	setScrubbing: () => void
}

export const TCCalendarControls = ({
	handleScrubChange,
	handleScrubChangeEnd,
	setScrubbing,
}: TCCalendarControlsProps) => {
	const [controlsWidth, setControlsWidth] = useState('')
	const circleWrapWidth = useRecoilValue(circlesWrapWidthState)
	const circlesBreakpoint = useRecoilValue(circlesBreakpointState)
	const confMode = useRecoilValue(confModeState)
	const confLowerMessage = useRecoilValue(confLowerMessageState)

	const controlsDirection = getBreakpointValue({ base: 'column', md: 'row' }, circlesBreakpoint) as ResponsiveValue<'column' | 'row'>
	const controlsJustify = getBreakpointValue({ base: 'flex-start', md: undefined }, circlesBreakpoint) as ResponsiveValue<'flex-start'> | undefined
	const controlsGap = getBreakpointValue({ base: 3, md: 3, xxxl: 4 }, circlesBreakpoint)
	const confFontSize2 = getBreakpointValue(cornersDataFontSize, circlesBreakpoint)

	useEffect(() => {
		setControlsWidth(`${circleWrapWidth}px`)
	}, [circleWrapWidth])

	if (!controlsWidth) return null

	return (
		<Flex
			className="tc-controls-wrap"
			direction={controlsDirection}
			justify={controlsJustify}
			w="100%"
			maxW={controlsWidth}
			pos="relative"
			gap={controlsGap}
		>
			{!confMode && (
				<BlockScrub
					onScrubChange={handleScrubChange}
					onScrubChangeStart={setScrubbing}
					onScrubChangeEnd={handleScrubChangeEnd}
				/>
			)}

			{confMode && confLowerMessage && (
				<Text
					w="100%"
					h="40px"
					textAlign="center"
					fontSize={confFontSize2}
					fontWeight="bold"
					color={BITCOIN_ORANGE}
				>
					{confLowerMessage}
				</Text>
			)}
		</Flex>
	)
}
