import { ModalOverlay as BaseModalOverlay } from '@chakra-ui/react'

export const ModalOverlay = () => {
	return (
		<BaseModalOverlay
			bg="blackAlpha.300"
			backdropFilter="blur(14px)"
			zIndex={1222}
		/>
	)
}
