import { type FC, type PropsWithChildren } from 'react'
import {
	Text,
	useColorModeValue,
	type TextProps,
} from '@chakra-ui/react'
import { useRecoilValue } from 'recoil'

import { circlesBreakpointState } from '../../state'
import { getBreakpointValue } from '../../utils'

type LabelProps = PropsWithChildren & TextProps

export const Label: FC<LabelProps> = ({ children, ...rest }) => {
	const circlesBreakpoint = useRecoilValue(circlesBreakpointState)
	const labelSizes = {
		base: '8px',
		xxs: '9px',
		xs: '10px',
		lg: '11px',
		xl: '12px',
		xxxl: '13px',
		jumbo: '14px',
	}
	const responsiveFontSize = getBreakpointValue(labelSizes, circlesBreakpoint)
	const color = useColorModeValue('black', 'white')

	return (
		<Text
			color={color}
			fontSize={responsiveFontSize}
			textTransform="uppercase"
			lineHeight="none"
			whiteSpace="nowrap"
			{...rest}
		>
			{children}
		</Text>
	)
}
