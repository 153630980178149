import { AppData } from './components/data'
import { RoutedApp } from './components/routing'

export const App = () => {
	return (
		<>
			<AppData />
			<RoutedApp />
		</>
	)
}
