import { atom } from 'recoil'

import type { Fees } from '../models'

export const feesState = atom({
	key: 'fees',
	default: {
		economyFee: 0,
		halfHourFee: 0,
		hourFee: 0,
		fastestFee: 0,
	} as Fees,
})

export const economyFeeState = atom({
	key: 'economyFee',
	default: 0,
})

export const halfHourFeeState = atom({
	key: 'halfHourFee',
	default: 0,
})

export const hourFeeState = atom({
	key: 'hourFee',
	default: 0,
})

export const fastestFeeState = atom({
	key: 'fastestFee',
	default: 0,
})
