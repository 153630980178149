import {
	format,
	formatDistanceToNow,
	differenceInMilliseconds,
	differenceInSeconds,
	sub,
} from 'date-fns'
import type { TimePickerValue } from 'react-time-picker'

import { dateLocales } from '../lang/dateFnsLocales'
import {
	DATE_FORMAT,
	TIME_FORMAT,
	FORMATS_CONFIG,
	MILLISECONDS_IN_24HOURS,
} from '../constants'
import { UserLocale } from '../lang/messages'

export const getTimeAgo = (date: Date, locale: UserLocale, ago: string) => {
	// retreive dynamic time ago
	const time = formatDistanceToNow(date, { includeSeconds: true, locale: dateLocales[locale] })

	// replace strings
	const trimmedMonths = time.replace('month', 'mon')
	const trimmedMinutes = trimmedMonths.replace('minute', 'min')
	const trimmedHalf = trimmedMinutes.replace('half a min', 'moments')
	const trimmedSeconds = trimmedHalf.replace('second', 'sec')
	const trimmedLessThan = trimmedSeconds.replace('less than', '<')
	const trimmedAlmost = trimmedLessThan.replace('almost', '<')
	const trimmedAbout = trimmedAlmost.replace('about', '~')
	const trimmedOver = trimmedAbout.replace('over', '>')

	// locale replace strings
	const aproxTimeBr = trimmedOver.replace('aproximadamente', 'aprox.')
	const updatedTimeAgo = aproxTimeBr

	// reversed ago order by locale
	const agoReversedLocales = ['ar', 'fr']
	const timeAgo = agoReversedLocales.includes(locale)
		? `${ago} ${updatedTimeAgo}`
		: `${updatedTimeAgo} ${ago}`

	return timeAgo
}

export const getDateFormat = (format: string) => {
	switch (format) {
		case DATE_FORMAT.alt:
			return FORMATS_CONFIG.altDate
		case DATE_FORMAT.europe:
			return FORMATS_CONFIG.europeDate
		case DATE_FORMAT.universal:
			return FORMATS_CONFIG.universalDate
		default:
			return FORMATS_CONFIG.defaultDate
	}
}

export const getMonthDayFormat = (format: string) => {
	switch (format) {
		case DATE_FORMAT.alt:
			return FORMATS_CONFIG.altMonthDay
		case DATE_FORMAT.europe:
			return FORMATS_CONFIG.europeMonthDay
		case DATE_FORMAT.universal:
			return FORMATS_CONFIG.universalMonthDay
		default:
			return FORMATS_CONFIG.defaultMonthDay
	}
}

export const getTimeFormat = (format: string) => {
	return format === TIME_FORMAT.h24
		? FORMATS_CONFIG.timeH24
		: FORMATS_CONFIG.defaultTime
}

export const getAmPm = (date: Date, timeFormat: string) =>
	timeFormat === TIME_FORMAT.h12
		? format(date, FORMATS_CONFIG.amPm)
		: ''

export const updateTime = (date: Date, time: TimePickerValue) => {
	const hoursMinutes = String(time).split(':')
	const hours = Number(hoursMinutes[0])
	const minutes = Number(hoursMinutes[1])
	const newDate = date

	newDate.setHours(hours)
	newDate.setMinutes(minutes)

	return newDate
}


export const getPercentageInLast24Hours = (date: Date) => {
	const twentyFourHoursAgo = sub(new Date(), { hours: 24 })
	const diff = differenceInMilliseconds(date, twentyFourHoursAgo)

	return (diff / MILLISECONDS_IN_24HOURS)
}

export const getCountdownString = (date: Date) => {
	const now = new Date()
	const totalSeconds = differenceInSeconds(date, now)

	// The future date is in the past
	if (totalSeconds < 0) {
		return ''
	}

	const days = Math.floor(totalSeconds / (3600 * 24))
	const hours = Math.floor((totalSeconds % (3600 * 24)) / 3600)
	const minutes = Math.floor((totalSeconds % 3600) / 60)
	// const seconds = totalSeconds % 60

	return `${days}d : ${hours}h : ${minutes}m`
}
