import {
	Button,
	chakra,
	shouldForwardProp,
	useColorModeValue,
} from '@chakra-ui/react'
import { motion, isValidMotionProp } from 'framer-motion'
import { ArrowRightIcon } from '@chakra-ui/icons'
import { useRecoilValue } from 'recoil'

import {
	blockState,
	staticModeState,
	circlesBreakpointState,
	editingBlockHeightState,
	scrubbingState,
	showBlockRippleState,
} from '../../../state'
import { BITCOIN_ORANGE } from '../../../constants'
import { getBreakpointValue } from '../../../utils'
import {
	buttonBreaks,
	buttonIconSize,
	buttonBottom,
} from './searchButton.constants'

interface TCLiveModeButtonProps {
	// eslint-disable-next-line no-unused-vars
	onScrubChangeEnd: (x: number) => void
	// eslint-disable-next-line no-unused-vars
	setScrubValue: (x: number) => void
}

const MotionBox = chakra(motion.div, {
	shouldForwardProp: (prop) => isValidMotionProp(prop) || shouldForwardProp(prop),
})

export const TCLiveModeButton = ({ onScrubChangeEnd, setScrubValue }: TCLiveModeButtonProps) => {
	const block = useRecoilValue(blockState)
	const scrubbing = useRecoilValue(scrubbingState)
	const staticMode = useRecoilValue(staticModeState)
	const showBlockRipple = useRecoilValue(showBlockRippleState)
	const editingBlockHeight = useRecoilValue(editingBlockHeightState)
	const circlesBreakpoint = useRecoilValue(circlesBreakpointState)

	const bg = useColorModeValue('white', 'black')
	const color = useColorModeValue('black', 'white')
	const colorAlt = useColorModeValue('rgba(0,0,0,0.5)', 'rgba(255,255,255,0.5)')
	const responsiveButtonHeight = getBreakpointValue(buttonBreaks, circlesBreakpoint)
	const iconSize = getBreakpointValue(buttonIconSize, circlesBreakpoint)
	const responsiveBottom = getBreakpointValue(buttonBottom, circlesBreakpoint)

	const blockHeight = block.height
	const interactionStyle = {
		borderColor: BITCOIN_ORANGE,
		color: BITCOIN_ORANGE,
	}
	const duration = showBlockRipple ? 0.5 : 1
	const responsiveColor = showBlockRipple ? 'orange100': color
	const responsiveBorderColor = showBlockRipple ? 'orange100': colorAlt

	const handleGoToLiveMode = () => {
		onScrubChangeEnd(blockHeight)
		setScrubValue(blockHeight)
	}

	return (
		<>
			{staticMode && !scrubbing && (
				<Button
					className="tc-search-button"
					role="group"
					onClick={handleGoToLiveMode}
					pos="absolute"
					zIndex={1200}
					right={0}
					bottom={responsiveBottom}
					variant="outline"
					w={responsiveButtonHeight}
					h={responsiveButtonHeight}
					bg={bg}
					textTransform="uppercase"
					color={responsiveColor}
					borderColor={responsiveBorderColor}
					borderWidth={2}
					borderRadius="50%"
					disabled={editingBlockHeight}
					_active={interactionStyle}
					_hover={interactionStyle}
				>
					<MotionBox
						animate={{
							opacity: [1, 0.3],
						}}
						// @ts-ignore
						transition={{
							duration: duration,
							ease: 'linear',
							repeat: Infinity,
							repeatType: 'loop',
						}}
					>
						<ArrowRightIcon
							boxSize={iconSize}
							transition="all 0.75s easeIn"
							_groupHover={{
								transform: 'scale(1.15)'
							}}
						/>
					</MotionBox>
				</Button>
			)}
		</>
	)
}