import type { FC, PropsWithChildren } from 'react'
import {
	Flex,
	Box,
	Text,
	Link,
	AccordionButton,
	AccordionPanel,
	AccordionIcon,
	ListItem,
	type TextProps
} from '@chakra-ui/react'
import { ExternalLinkIcon } from '@chakra-ui/icons'
import { useIntl } from 'react-intl'

import { Label } from '../../shared'
import { BITCOIN_ORANGE } from '../../../constants'

interface AccordionHeaderProps {
	title: string
}

export const SettingsOptionLabel: FC<PropsWithChildren> = ({ children }) => {
	return (
		<Label fontSize="xs" fontWeight="bold" lineHeight="shorter">
			{children}
		</Label>
	)
}

export const AccordionHeader = ({ title }: AccordionHeaderProps) => {
	return (
		<AccordionButton _expanded={{ color: BITCOIN_ORANGE }}>
			<Box
				flex="1"
				textAlign="center"
				_hover={{ color: BITCOIN_ORANGE }}
			>
				<Text
					fontSize="xs"
					fontWeight="bold"
					textTransform="uppercase"
				>
					{title}
				</Text>
			</Box>
			<AccordionIcon />
		</AccordionButton>
	)
}

export const AccordionContent: FC<PropsWithChildren> = ({ children }) => {
	return (
		<AccordionPanel pb={4}>
			{children}
		</AccordionPanel>
	)
}

type PProps = TextProps & PropsWithChildren
export const P: FC<PProps> = ({ children, ...rest }) => {
	return (
		<Text fontSize="sm" mb={2} {...rest}>
			{children}
		</Text>
	)
}

export const LI: FC<PropsWithChildren> = ({ children }) => {
	return (
		<ListItem fontSize="sm" lineHeight="none" mb={3}>
			{children}
		</ListItem>
	)
}

type TerminologyListItemProps = {
	title: string
	definition: string
	link?: string
}
export const TerminologyListItem = ({
	title,
	definition,
	link,
}: TerminologyListItemProps) => {
	const intl = useIntl()

	return (
		<LI>
			<Flex direction="column">
				<Text
					flex="0 0 23.5%"
					fontSize="xs"
					fontWeight="bold"
					textTransform="uppercase"
					mb="3px"
				>
					{title}
				</Text>
				<Text
					lineHeight={1.3}
				>
					{definition}
					{link && (
						<>
							&nbsp;<Link
								href={link}
								color={BITCOIN_ORANGE}
								display="inline-flex"
								alignItems="center"
								gap="3px"
								isExternal
							>
								{intl.formatMessage({ id: 'info.terms.read_more' })}<ExternalLinkIcon fontSize="xs" />
							</Link>
						</>
					)}
				</Text>
			</Flex>
		</LI>
	)
}
