import {
	Flex,
	Button,
	Text,
	useColorModeValue,
} from '@chakra-ui/react'
import { useRecoilValue } from 'recoil'

import {
	circlesBreakpointState,
	staticModeState,
	blockState,
	scrubBlockState,
} from '../../state'
import { getBitcoinEpoch, getBreakpointValue } from '../../utils'
import {
	BITCOIN_ORANGE,
	EPOCH_1_BLOCK,
	EPOCH_2_BLOCK,
	EPOCH_3_BLOCK,
	EPOCH_4_BLOCK,
	EPOCH_5_BLOCK,
} from '../../constants'

interface EpochButtonProp {
	text: string
	epochBlock: number
	// eslint-disable-next-line no-unused-vars
	handleChangeBlock: (x: number) => void
}
interface EpochButtonsProps {
    // eslint-disable-next-line no-unused-vars
	handleChangeBlock: (x: number) => void
}

const EpochButton = ({ text, epochBlock, handleChangeBlock }: EpochButtonProp) => {
	const circlesBreakpoint = useRecoilValue(circlesBreakpointState)
	const staticMode = useRecoilValue(staticModeState)
	const block = useRecoilValue(blockState)
	const scrubBlock = useRecoilValue(scrubBlockState)
	const scrubBlockHeight = scrubBlock.height
	const scrubEpoch = getBitcoinEpoch(scrubBlockHeight)
	const blockHeight = block.height
	const epoch = blockHeight ? getBitcoinEpoch(blockHeight) : ''
	const currentEpoch = staticMode ? scrubEpoch : epoch
	const thisEpoch = getBitcoinEpoch(epochBlock)
	const shouldHighlight = thisEpoch === currentEpoch
	const markerColor = shouldHighlight ? BITCOIN_ORANGE : 'transparent'
	const bg = useColorModeValue('white', 'black')
	const colorAlt = useColorModeValue('rgba(0,0,0,0.5)', 'rgba(255,255,255,0.5)')
	const color = scrubBlockHeight === epochBlock ? BITCOIN_ORANGE : colorAlt

	const epochButtonsFontSize = getBreakpointValue({ base: '13px', xxl: '14px' }, circlesBreakpoint)

	return (
		<Flex
			pos="relative"
			direction="column"
			justify="space-between"
			align="center"
			alignSelf="flex-start"
			h="34px"
			w="34px"
			_after={{
				content: '""',
				h: '6px',
				w: '6px',
				pos: 'absolute',
				top: 7,
				bgColor: markerColor,
				filter: 'blur(2px)'
			}}
		>
			<Button
				onClick={() => handleChangeBlock(epochBlock)}
				flexGrow={0}
				variant="unstyled"
				minW="24px"
				h="24px"
				w="24px"
				p={0}
				bg={bg}
				borderWidth={2}
				borderRadius="50%"
				cursor="pointer"
				color={color}
				borderColor={color}
				_active={{
					borderColor: BITCOIN_ORANGE,
					color: BITCOIN_ORANGE,
				}}
				_hover={{
					borderColor: BITCOIN_ORANGE,
					color: BITCOIN_ORANGE,
				}}
			>
				<Flex
					w="100%"
					h="100%"
					align="center"
					justify="center"
				>
					<Text
						textStyle="roman"
						textAlign="center"
						fontSize={epochButtonsFontSize}
						fontWeight="bold"
						lineHeight="none"
						letterSpacing="-1px"
						mt="1px"
					>
						{text}
					</Text>
				</Flex>
			</Button>
		</Flex>
	)
}

export const EpochButtons = ({ handleChangeBlock }: EpochButtonsProps) => {
	const circlesBreakpoint = useRecoilValue(circlesBreakpointState)

	const epochButtonsGap = getBreakpointValue({ base: 1, sm: '5px', md: '3px', lg: '5px', xl: '6px', xxl: 2, xxxl: '10px' }, circlesBreakpoint)

	return (
		<Flex gap={epochButtonsGap} mb={2}>
			<EpochButton text="I" epochBlock={EPOCH_1_BLOCK} handleChangeBlock={handleChangeBlock} />
			<EpochButton text="II" epochBlock={EPOCH_2_BLOCK} handleChangeBlock={handleChangeBlock} />
			<EpochButton text="III" epochBlock={EPOCH_3_BLOCK} handleChangeBlock={handleChangeBlock} />
			<EpochButton text="IV" epochBlock={EPOCH_4_BLOCK} handleChangeBlock={handleChangeBlock} />
			<EpochButton text="V" epochBlock={EPOCH_5_BLOCK} handleChangeBlock={handleChangeBlock} />
		</Flex>    
	)
}
