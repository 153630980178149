import { atom } from 'recoil'

export const totalAdressesState = atom({
	key: 'totalAdresses',
	default: 0,
})

export const totalUtxosState = atom({
	key: 'totalUtxos',
	default: 0,
})

export const totalVbState = atom({
	key: 'totalVb',
	default: 0,
})

export const totalDiskState = atom({
	key: 'totalDisk',
	default: 0,
})

export const chainHeightState = atom({
	key: 'chainHeight',
	default: 0,
})

export const totalTxnsCountState = atom({
	key: 'totalTxnsCount',
	default: 0,
})
