import { atom } from 'recoil'

export const supplyIntroStartState = atom({
	key: 'supplyIntroStart',
	default: false,
})

export const supplyIntroCompleteState = atom({
	key: 'supplyIntroComplete',
	default: false,
})

export const halvingIntroStartState = atom({
	key: 'halvingIntroStart',
	default: false,
})

export const halvingIntroCompleteState = atom({
	key: 'halvingIntroComplete',
	default: false,
})

export const daIntroStartState = atom({
	key: 'daIntroStart',
	default: false,
})

export const daIntroCompleteState = atom({
	key: 'daIntroComplete',
	default: false,
})

export const past24IntroStartState = atom({
	key: 'past24IntroStart',
	default: false,
})

export const past24IntroCompleteState = atom({
	key: 'past24IntroComplete',
	default: false,
})
