import { useState } from 'react'
import type { FC, PropsWithChildren } from 'react'
import {
	Flex,
	Box,
	Text,
	Accordion,
	AccordionItem,
	Link,
	UnorderedList,
	type TextProps,
	useColorModeValue,
} from '@chakra-ui/react'
import { FormattedMessage, useIntl } from 'react-intl'
import { CopyToClipboard } from 'react-copy-to-clipboard'

import { useAppSelector } from '../../../hooks'
import { TranslatorAttribution } from './TranslatorAttribution'
import {
	AccordionHeader,
	AccordionContent,
	TerminologyListItem,
} from '../settings/Settings.components'
import {
	X,
	NostrLogo,
	NostrLogoProps,
} from '../../svg'
import { BITCOIN_ORANGE } from '../../../constants'
import { getWhitepaperUrl } from '../../../lang/lang.utils'
import { IconLi } from '../../shared'

type PProps = TextProps & PropsWithChildren
export const P: FC<PProps> = ({ children, ...rest }) => {
	return (
		<Text
			fontSize="sm"
			mb={2}
			{...rest}
		>
			{children}
		</Text>
	)
}

export const TCInfoAccordion = () => {
	const { userLocale } = useAppSelector(({ settings }) => settings)
	const intl = useIntl()
	const [npubCopied, setNpubCopied] = useState(false)
	const color = useColorModeValue('black', 'white')
	const NPUB = 'npub1jdgclywl55wc4neey97de57jenghsseuh8njx6z5f2kdwsfvk5xqhd2mys'

	return (
		<Flex direction="column" color={color}>
			<Accordion defaultIndex={[0]}>
				<AccordionItem>
					<AccordionHeader title={intl.formatMessage({ id: 'info.panel_label.about' })} />

					<AccordionContent>
						<Box
							maxH="40vh"
							overflowY="scroll"
							mb={2}
						>
							<P>
								<FormattedMessage id="info.about.copy1" />
							</P>
							<P>
								<FormattedMessage id="info.about.copy2" />
							</P>

							<TranslatorAttribution />
						</Box>
					</AccordionContent>
				</AccordionItem>

				<AccordionItem>
					<AccordionHeader title={intl.formatMessage({ id: 'info.panel_label.terminology' })} />

					<AccordionContent>
						<Box
							maxH="40vh"
							overflowY="scroll"
							mb={2}
						>
							<UnorderedList maxW="100%">
								<TerminologyListItem
									title={intl.formatMessage({ id: 'info.terms.title.block' })}
									definition={intl.formatMessage({ id: 'info.terms.definition.block' })}
								/>
								<TerminologyListItem
									title={intl.formatMessage({ id: 'info.terms.title.block_height' })}
									definition={intl.formatMessage({ id: 'info.terms.definition.block_height' })}
								/>
								<TerminologyListItem
									title={intl.formatMessage({ id: 'info.terms.title.coinbase' })}
									definition={intl.formatMessage({ id: 'info.terms.definition.coinbase' })}
								/>
								<TerminologyListItem
									title={intl.formatMessage({ id: 'info.terms.title.difficulty_adjust' })}
									definition={intl.formatMessage({ id: 'info.terms.definition.difficulty_adjust' })}
								/>
								<TerminologyListItem
									title={intl.formatMessage({ id: 'info.terms.title.epoch' })}
									definition={intl.formatMessage({ id: 'info.terms.definition.epoch' })}
								/>
								<TerminologyListItem
									title={intl.formatMessage({ id: 'info.terms.title.exchange' })}
									definition={intl.formatMessage({ id: 'info.terms.definition.exchange' })}
								/>
								<TerminologyListItem
									title={intl.formatMessage({ id: 'info.terms.title.fees' })}
									definition={intl.formatMessage({ id: 'info.terms.definition.fees' })}
								/>
								<TerminologyListItem
									title={intl.formatMessage({ id: 'info.terms.title.halving' })}
									definition={intl.formatMessage({ id: 'info.terms.definition.halving' })}
								/>
								<TerminologyListItem
									title={intl.formatMessage({ id: 'info.terms.title.hashrate' })}
									definition={intl.formatMessage({ id: 'info.terms.definition.hashrate' })}
								/>
								<TerminologyListItem
									title={intl.formatMessage({ id: 'info.terms.title.lightning_liquidity' })}
									definition={intl.formatMessage({ id: 'info.terms.definition.lightning_liquidity' })}
								/>
								<TerminologyListItem
									title={intl.formatMessage({ id: 'info.terms.title.mempool' })}
									definition={intl.formatMessage({ id: 'info.terms.definition.mempool' })}
								/>
								<TerminologyListItem
									title={intl.formatMessage({ id: 'info.terms.title.mempool_depth' })}
									definition={intl.formatMessage({ id: 'info.terms.definition.mempool_depth' })}
								/>
								<TerminologyListItem
									title={intl.formatMessage({ id: 'info.terms.title.mempool_unconfirmed' })}
									definition={intl.formatMessage({ id: 'info.terms.definition.mempool_unconfirmed' })}
								/>
								<TerminologyListItem
									title={intl.formatMessage({ id: 'info.terms.title.mempool_inflow' })}
									definition={intl.formatMessage({ id: 'info.terms.definition.mempool_inflow' })}
								/>
								<TerminologyListItem
									title={intl.formatMessage({ id: 'info.terms.title.miners' })}
									definition={intl.formatMessage({ id: 'info.terms.definition.miners' })}
								/>
								<TerminologyListItem
									title={intl.formatMessage({ id: 'info.terms.title.nodes' })}
									definition={intl.formatMessage({ id: 'info.terms.definition.nodes' })}
								/>
								<TerminologyListItem
									title={intl.formatMessage({ id: 'info.terms.title.pow' })}
									definition={intl.formatMessage({ id: 'info.terms.definition.pow' })}
									link="https://dergigi.com/threads/pow-is-essential"
								/>
								<TerminologyListItem
									title={intl.formatMessage({ id: 'info.terms.title.sats' })}
									definition={intl.formatMessage({ id: 'info.terms.definition.sats' })}
								/>
								<TerminologyListItem
									title={intl.formatMessage({ id: 'info.terms.title.subsidy' })}
									definition={intl.formatMessage({ id: 'info.terms.definition.subsidy' })}
								/>
								<TerminologyListItem
									title={intl.formatMessage({ id: 'info.terms.title.supply' })}
									definition={intl.formatMessage({ id: 'info.terms.definition.supply' })}
								/>
								<TerminologyListItem
									title={intl.formatMessage({ id: 'info.terms.title.timechain' })}
									definition={intl.formatMessage({ id: 'info.terms.definition.timechain' })}
								/>
								<TerminologyListItem
									title={intl.formatMessage({ id: 'info.terms.title.tx' })}
									definition={intl.formatMessage({ id: 'info.terms.definition.tx' })}
								/>
								<TerminologyListItem
									title={intl.formatMessage({ id: 'info.terms.title.vbytes' })}
									definition={intl.formatMessage({ id: 'info.terms.definition.vbytes' })}
								/>
							</UnorderedList>
						</Box>
					</AccordionContent>
				</AccordionItem>

				<AccordionItem>
					<AccordionHeader title={intl.formatMessage({ id: 'info.panel_label.resources' })} />

					<AccordionContent>
						<Box
							maxH="40vh"
							overflowY="scroll"
							mb={2}
						>
							<P lineHeight="shorter" fontWeight="bold" mb={2}>
								<FormattedMessage id="info.resources.inspiration" />:
							</P>
							<UnorderedList styleType="none" ml={0}>
								<IconLi
									imageSrc="/images/mempool-space-favicon.png"
									link="https://mempool.space"
									title="Mempool.space"
									description={intl.formatMessage({ id: 'info.resources.mempool_space' })}
								/>
								<IconLi
									imageSrc="/images/bcm-favicon.png"
									link="https://blockclockmini.com"
									title="The BlockClock"
									description={intl.formatMessage({ id: 'info.resources.blockclock' })}
								/>
								<IconLi
									imageSrc="/images/clark-moody-favicon.png"
									link="https://bitcoin.clarkmoody.com/dashboard"
									title="Clark Moody Bitcoin Dashboard"
									description={intl.formatMessage({ id: 'info.resources.clarkmoody' })}
								/>
								<IconLi
									imageSrc="/images/tcs-favicon.ico"
									link="https://timechainstats.com"
									title="Timechain Stats"
									description={intl.formatMessage({ id: 'info.resources.timechainstats' })}
								/>
								<IconLi
									imageSrc="/images/bitfeed-favicon.png"
									link="https://bitfeed.live"
									title="Bitfeed"
									description={intl.formatMessage({ id: 'info.resources.bitfeed' })}
								/>
								<IconLi
									imageSrc="/images/timechain-index-favicon.ico"
									link="https://timechainindex.com"
									title="Timechain Index"
									description="Bitcoin Holdings By Exchanges, ETFs, ETPs, OTCs, Funds & Companies. Provides chainstate data for Timechain Calendar."
								/>
							</UnorderedList>

							<P lineHeight="shorter" fontWeight="bold" mt={6} mb={2}>
								Bitcoin protocol:
							</P>
							<UnorderedList styleType="none" ml={0}>
								<IconLi
									imageSrc="/images/bitcoin-logo.png"
									link={getWhitepaperUrl(userLocale)}
									title={intl.formatMessage({ id: 'info.resources.whitepaper' })}
									description={intl.formatMessage({ id: 'info.resources.whitepaper_description' })}
								/>
								<IconLi
									imageSrc="/images/bitcoin-logo.png"
									link="https://bitcoincore.org/"
									title="Bitcoin Core"
									description="An open source project which maintains and releases Bitcoin client software called “Bitcoin Core”."
								/>
							</UnorderedList>

							<P lineHeight="shorter" fontWeight="bold" mt={6} mb={2}>
								<FormattedMessage id="info.resources.education" />:
							</P>
							<UnorderedList styleType="none" ml={0}>
								{userLocale === 'ru' && (
									<IconLi
										imageSrc="/images/21ideas-org.jpg"
										link="https://21ideas.org/"
										title="21 идея"
										description="Всеобъемлющий русскоязычный образовательный ресурс, посвященный Биткоину, и сильное биткоин-сообщество, работающее над образовательным проектом с открытым исходным кодом."
									/>
								)}
								<IconLi
									imageSrc="/images/dergigi-avatar.jpeg"
									link="https://dergigi.com/words"
									title={intl.formatMessage({ id: 'info.resources.words_by_gigi' })}
									description={intl.formatMessage({ id: 'info.resources.gigi_description' })}
								/>
								<IconLi
									imageSrc="/images/wtf-nixonsquareicon.webp"
									link="https://wtfhappenedin1971.com"
									title={intl.formatMessage({ id: 'info.resources.wtf_1971' })}
									description={intl.formatMessage({ id: 'info.resources.wtf_1971_description' })}
								/>
							</UnorderedList>
						</Box>
					</AccordionContent>
				</AccordionItem>

				<AccordionItem>
					<AccordionHeader title={intl.formatMessage({ id: 'info.panel_label.contact' })} />

					<AccordionContent>
						<Flex align="flex-start" gap="7px" mb={1}>
							<X width={20} fill={color} />
							<P>
								<Link
									color={BITCOIN_ORANGE}
									fontWeight="bold"
									href="https://twitter.com/TimechainCaL"
									isExternal
								>
									@TimechainCaL
								</Link>
							</P>
						</Flex>

						<Flex gap="3px" mb={3}>
							<NostrLogo width="26px" style={{ flexShrink: 0, marginTop: '2px' } as NostrLogoProps} />
							<CopyToClipboard
								text={NPUB}
								onCopy={() => {
									setNpubCopied(true)
									setTimeout(() => {
										setNpubCopied(false)
									}, 4000)
								}}
							>
								<Text
									fontSize="sm"
									fontWeight="bold"
									color={BITCOIN_ORANGE}
									cursor="pointer"
									_hover={{
										textDecoration: 'underline'
									}}
								>
									npub...
								</Text>
							</CopyToClipboard>
							{npubCopied && (
								<Text
									fontSize="sm"
									color="whiteAlpha.700"
								>
									<FormattedMessage id="info.contact.copied" />!
								</Text>
							)}
						</Flex>

						<P fontWeight="bold">
							<FormattedMessage id="info.contact.created_by" />
						</P>
					</AccordionContent>
				</AccordionItem>
			</Accordion>
		</Flex>
	)
}
