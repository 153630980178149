import { useState, type ChangeEvent } from 'react'
import {
	DragDropContext,
	Droppable,
	Draggable,
	type DropResult,
} from 'react-beautiful-dnd'
import {
	Flex,
	Box,
	Checkbox,
	Image,
	Button,
	Input,
	Text,
	useColorModeValue,
} from '@chakra-ui/react'
import { v4 as uuidv4 } from 'uuid'

import { useAppDispatch, useAppSelector } from '../../../hooks'
import { Label } from '../../shared'
import { BITCOIN_ORANGE } from '../../../constants'
import {
	setSponsors,
	setShowSlideshow,
	setEdgeToEdge,
} from './sponsorsReducer'

export interface Sponsor {
	id: string
	imgUrl: string
	bgColor?: string
}

interface SponsorSelectorProps {
	timing: number
	// eslint-disable-next-line no-unused-vars
	setTiming: (x: number) => void
}

export const SponsorSelector = ({ timing, setTiming }: SponsorSelectorProps) => {
	const dispatch = useAppDispatch()
	const {
		sponsors,
		showSlideshow,
		edgeToEdge,
	} = useAppSelector((state) => state.confModeSponsors)
	const [newSponsorImgUrl, setNewSponsorImgUrl] = useState('')
	const [newSponsorBgColor, setNewSponsorBgColor] = useState('')
	const [editing, setEditing] = useState(false)
	const [editingId, setEditingId] = useState('')

	const colorAlt = useColorModeValue('rgba(0,0,0,0.5)', 'rgba(255,255,255,0.5)')

	const interactionStyles = {
		borderColor: BITCOIN_ORANGE,
		color: BITCOIN_ORANGE,
	}

	const actionButtonLabel = editing ? 'UPDATE SPONSOR' : 'ADD SPONSOR'

	const getListStyle = (isDraggingOver: boolean) => ({
		background: isDraggingOver ? 'rgba(255, 255, 255, 0.5)' : 'rgba(255, 255, 255, 0.25)',
	})

	const reorderSponsors = (list: Sponsor[], startIndex: number, endIndex: number) => {
		const result = Array.from(list)
		const [removed] = result.splice(startIndex, 1)
		result.splice(endIndex, 0, removed)

		return result
	}

	const onDragEnd = (result: DropResult) => {
		if (!result.destination) {
			return
		}

		const reorderedSponsors = reorderSponsors(
			sponsors,
			result.source.index,
			result.destination.index
		)

		dispatch(setSponsors(reorderedSponsors))
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const handleChangeNewSponsorImgUrl = (event: any) => {
		setNewSponsorImgUrl(event.target.value)
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const handleChangeNewSponsorBgColor = (event: any) => {
		setNewSponsorBgColor(event.target.value)
	}

	const resetSponsorForm = () => {
		setNewSponsorImgUrl('')
		setNewSponsorBgColor('')
		setEditing(false)
		setEditingId('')
	}

	const handleShowSlideshowChange = (event: ChangeEvent<HTMLInputElement>) => {
		dispatch(setShowSlideshow(event.target?.checked))
	}

	const handleEdgeToEdgeChange = (event: ChangeEvent<HTMLInputElement>) => {
		dispatch(setEdgeToEdge(event.target?.checked))
	}

	const handleTimingChange = (event: ChangeEvent<HTMLInputElement>) => {
		setTiming(Number(event.target.value))
	}

	const handleAddSponsor = () => {
		const newSponsorId = uuidv4()
		const newSponsorsList = [
			...sponsors,
			{
				id: newSponsorId,
				imgUrl: newSponsorImgUrl,
				bgColor: newSponsorBgColor,
			}
		]
		dispatch(setSponsors(newSponsorsList))
		resetSponsorForm()
	}

	const handleRemoveSponsor = (id: string) => {
		const updatedSponsorList = sponsors.filter((sponsor) => sponsor.id !== id)
		dispatch(setSponsors(updatedSponsorList))
	}

	const handleEditSponsor = (id: string) => {
		const sponsorToEdit = sponsors.find((sponsor) => sponsor.id === id)
		if (sponsorToEdit) {
			setEditing(true)
			setEditingId(id)
			setNewSponsorImgUrl(sponsorToEdit.imgUrl)
			if (sponsorToEdit.bgColor) {
				setNewSponsorBgColor(sponsorToEdit.bgColor)
			}
		}
	}

	const handleUpdateSponsor = (id: string) => {
		const updatedSponsors = sponsors.map((sponsor) => sponsor.id === id
			? {
				id,
				imgUrl: newSponsorImgUrl,
				bgColor: newSponsorBgColor,
			}
			: sponsor
		)
		dispatch(setSponsors(updatedSponsors))
		resetSponsorForm()
	}

	const HR = () => <hr style={{ opacity: 0.2, marginTop: '10px', marginBottom: '10px' }} />

	return (
		<Flex direction="column">
			<Flex direction="column" mb={8}>
				<Flex direction="column">
					<Box>
						<Checkbox
							colorScheme="orange"
							isChecked={showSlideshow}
							onChange={(e) => handleShowSlideshowChange(e)}
						>
							<Label fontSize="xs" fontWeight="bold" lineHeight="shorter">
								Show Slideshow
							</Label>
						</Checkbox>
					</Box>

					<Box>
						<Checkbox
							colorScheme="orange"
							isChecked={edgeToEdge}
							onChange={(e) => handleEdgeToEdgeChange(e)}
						>
							<Label fontSize="xs" fontWeight="bold" lineHeight="shorter">
								Slideshow Edge-to-edge
							</Label>
						</Checkbox>
					</Box>

					<Box>
						<Label mb={1} mt={2}>
							<b>Slideshow change time</b>
						</Label>
						<Flex align="center" gap={2}>
							<Input
								type="number"
								value={timing}
								onChange={handleTimingChange}
								w="80px"
								max={100}
								min={1}
							/>
							<Label fontWeight="bold">Seconds</Label>
						</Flex>
					</Box>
				</Flex>

				<HR />

				<Flex direction="column" gap={4} mb={4}>
					<Flex direction="column">
						<Label mb={1}>
							<b>Sponsor Logo Img URL</b>
						</Label>
						<Input
							value={newSponsorImgUrl}
							// eslint-disable-next-line @typescript-eslint/no-explicit-any
							onChange={(x: any) => handleChangeNewSponsorImgUrl(x)}
							borderColor={colorAlt}
						/>
					</Flex>

					<Flex direction="column">
						<Label mb={1}>
							<b>Background Color</b> <span style={{ textTransform: 'none', marginLeft: '3px' }}>(Optional - any valid CSS string)</span>
						</Label>
						<Input
							value={newSponsorBgColor}
							// eslint-disable-next-line @typescript-eslint/no-explicit-any
							onChange={(x: any) => handleChangeNewSponsorBgColor(x)}
							borderColor={colorAlt}
						/>
					</Flex>
				</Flex>

				<Flex gap={4}>
					<Button
						onClick={editing
							? () => handleUpdateSponsor(editingId)
							: handleAddSponsor
						}
						variant="outline"
						colorScheme="black"
						fontSize="sm"
						borderWidth={2}
						textTransform="uppercase"
						disabled={newSponsorImgUrl === ''}
						_active={interactionStyles}
						_hover={interactionStyles}
					>
						{actionButtonLabel}
					</Button>

					<Button
						onClick={resetSponsorForm}
						variant="outline"
						colorScheme="black"
						fontSize="sm"
						borderWidth={2}
						textTransform="uppercase"
						disabled={editingId === '' && newSponsorImgUrl === ''}
						_active={interactionStyles}
						_hover={interactionStyles}
					>
						CLEAR
					</Button>
				</Flex>
			</Flex>

			<DragDropContext onDragEnd={onDragEnd}>
				<Droppable droppableId="sponsorsDnd" direction="horizontal">
					{(provided, snapshot) => (
						<Flex
							{...provided.droppableProps}
							ref={provided.innerRef}
							style={getListStyle(snapshot.isDraggingOver)}
							wrap="wrap"
							align="center"
							p={4}
						>
							{sponsors.length === 0 && (
								<Text fontStyle="italic">
									NO SPONSORS ENTERED
								</Text>
							)}

							{sponsors.map((sponsor, index) => (
								<Draggable key={sponsor.id} draggableId={sponsor.id} index={index}>
									{(provided, snapshot) => {
										const sponsorBgColor = sponsor.bgColor ? sponsor.bgColor : 'black'

										return (
											<Flex
												ref={provided.innerRef}
												{...provided.draggableProps}
												{...provided.dragHandleProps}
												direction="column"
												align="center"
												opacity={snapshot.isDragging ? 0.65 : 1}
												bg={sponsorBgColor}
											>
												<Flex
													w="100%"
													justify="flex-end"
													align="center"
													bg="black"
													p={1}
													gap={1}
												>
													<Button
														onClick={() => handleEditSponsor(sponsor.id)}
														variant="outline"
														size="xs"
														fontSize="xxs"
														p={1}
														colorScheme="black"
														_active={interactionStyles}
														_hover={interactionStyles}
													>
														EDIT
													</Button>

													<Button
														onClick={() => handleRemoveSponsor(sponsor.id)}
														variant="outline"
														size="xs"
														fontSize="xxs"
														p={1}
														colorScheme="black"
														_active={interactionStyles}
														_hover={interactionStyles}
													>
														REMOVE
													</Button>
												</Flex>

												<Image
													boxSize="200px"
													objectFit="contain"
													src={sponsor.imgUrl}
												/>
											</Flex>
										)
									}}
								</Draggable>
							))}
							{provided.placeholder}
						</Flex>
					)}
				</Droppable>
			</DragDropContext>
		</Flex >
	)
}
