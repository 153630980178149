import { useState } from 'react'
import {
	Flex,
	Text,
	Button,
	NumberInput,
	NumberInputField,
	NumberInputStepper,
	NumberIncrementStepper,
	NumberDecrementStepper,
	Spinner,
	useColorModeValue,
} from '@chakra-ui/react'
import { FormattedMessage } from 'react-intl'

import { BITCOIN_ORANGE } from '../../../constants'

interface BTCPayFormProps {
	onClose: () => void
}

interface AmountButtonProps {
	amount: number
}

export const BTCPayForm = ({ onClose }: BTCPayFormProps) => {
	const [donationAmount, setDonationAmount] = useState<number | string>(0)
	const [loading, setLoading] = useState(false)
	const color = useColorModeValue('black', 'white')
	const colorAlt = useColorModeValue('rgba(0,0,0,0.7)', 'rgba(255,255,255,0.7)')

	const submitDisabled = loading || Number(donationAmount) < 1

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const handleFormSubmit = (event: any) => {
		event.preventDefault()
		setLoading(true)
		setTimeout(() => {
			onClose()
		}, 3000)
		const xhttp = new XMLHttpRequest()
		xhttp.onreadystatechange = function () {
			if (this.readyState == 4 && this.status == 200 && this.responseText) {
				window.btcpay.appendInvoiceFrame(JSON.parse(this.responseText).invoiceId)
			}
		}
		xhttp.open('POST', event.target.getAttribute('action'), true)
		xhttp.send(new FormData(event.target))
	}

	const handleDonateAmountButtonClick = (amount: number) => {
		setDonationAmount(amount)
	}

	const AmountButton = ({ amount }: AmountButtonProps) => {
		return (
			<Button
				onClick={() => handleDonateAmountButtonClick(amount)}
				variant="outline"
				size="sm"
				borderColor={color}
				borderWidth={2}
				_hover={{
					color: BITCOIN_ORANGE,
					borderColor: BITCOIN_ORANGE,
				}}
				_active={{
					color: BITCOIN_ORANGE,
					borderColor: BITCOIN_ORANGE,
				}}
			>
				{amount.toLocaleString()}
			</Button>
		)
	}

	return (
		<form
			method="POST"
			action="https://btcpay722165.lndyn.com/api/v1/invoices"
			className="btcpay-form btcpay-form--block"
			onSubmit={handleFormSubmit}
		>
			<input type="hidden" name="storeId" value="3MBLUY4QeyXkEJqiFWCHnt1ruGPpt3X6EW2CTkpptgGa" />
			<input type="hidden" name="jsonResponse" value="true" />
			<input type="hidden" name="currency" value="SATS" />

			<Flex
				w="100%"
				direction="column"
				align="center"
				justify="center"
				gap={4}
				mb={2}
			>
				<Text
					align="center"
					color={colorAlt}
					fontStyle="italic"
					fontWeight="bold"
					fontSize="sm"
				>
					<FormattedMessage id="donate.intro" />
				</Text>

				<Text
					align="center"
					fontWeight="bold"
					fontSize="sm"
					mb={4}
				>
					<FormattedMessage id="donate.thank_you" /> 🙏🧡
				</Text>

				<Flex
					direction="column"
					align="center"
					justify="center"
					gap={4}
					mb={2}
					w="90%"
				>
					<Flex
						wrap="wrap"
						gap={2}
						align="center"
						justify="center"
					>
						<AmountButton amount={1000} />
						<AmountButton amount={10000} />
						<AmountButton amount={100000} />
						<AmountButton amount={1000000} />
						<AmountButton amount={10000000} />
						<AmountButton amount={100000000} />
					</Flex>

					<Flex align="center" gap={2}>
						<NumberInput
							className="btcpay-input-price"
							inputMode="numeric"
							name="price"
							min={0}
							max={100000000}
							defaultValue={donationAmount}
							value={donationAmount}
							data-price={donationAmount}
							onChange={(x) => setDonationAmount(x)}
							allowMouseWheel={true}
							borderColor={colorAlt}
						>
							<NumberInputField />
							<NumberInputStepper>
								<NumberIncrementStepper />
								<NumberDecrementStepper />
							</NumberInputStepper>
						</NumberInput>

						<Text
							color={colorAlt}
							fontWeight="bold"
							fontSize="sm"
						>
							SATS
						</Text>
					</Flex>
				</Flex>

				<Button
					type="submit"
					className="submit"
					name="submit"
					variant="outline"
					minW="168px"
					minH="46px"
					borderRadius="md"
					color={BITCOIN_ORANGE}
					borderColor={BITCOIN_ORANGE}
					borderWidth={2}
					textTransform="uppercase"
					cursor="pointer"
					transition="all 0.21s ease"
					_hover={{
						color: 'white',
						borderColor: 'white',
						bgColor: BITCOIN_ORANGE,
					}}
					_active={{
						color: 'white',
						borderColor: 'white',
						bgColor: BITCOIN_ORANGE,
					}}
					disabled={submitDisabled}
				>
					{loading
						? <Spinner />
						: <FormattedMessage id="info.contact.donate" />
					}
				</Button>
			</Flex>
		</form>
	)
}
