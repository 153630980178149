import {
	extendTheme,
	createMultiStyleConfigHelpers,
	type ThemeConfig,
} from '@chakra-ui/react'
import { switchAnatomy } from '@chakra-ui/anatomy'
import { BITCOIN_ORANGE } from '../constants'

const { definePartsStyle, defineMultiStyleConfig } =
	createMultiStyleConfigHelpers(switchAnatomy.keys)

export const theme: ThemeConfig = extendTheme({
	useSystemColorMode: false,
	initialColorMode: 'dark',
	styles: {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		global: (props: any) => ({
			body: {
				bg: props.colorMode === 'light' ? 'white' : 'black',
				color: props.colorMode === 'light' ? 'black' : 'white',
				margin: 0,
				// eslint-disable-next-line quotes
				fontFamily: `-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif`,
			},
		}),
	},
	colors: {
		orange: {
			500: BITCOIN_ORANGE,
			600: BITCOIN_ORANGE,
		},
		green: {
			300: '#066600',
			500: '#4ebd49',
			700: '#10e905'
		},
		red: {
			500: '#ff0000',
			700: '#ac0202',
			900: '#820101'
		},
		// use these custom tokens below
		// RED
		red100: '#ff0000',
		darkred80: '#ff3333',
		darkred60: '#ff6666',
		darkred40: '#ff9999',
		darkred20: '#ffcccc',
		lightred80: '#cc0000',
		lightred60: '#990000',
		lightred40: '#660000',
		lightred20: '#330000',
		// ORANGE
		orange100: '#f7931a',
		darkorange80: '#f9a948',
		darkorange60: '#fabe76',
		darkorange40: '#fcd4a3',
		darkorange20: '#fde9d1',
		lightorange80: '#c67615',
		lightorange60: '#945810',
		lightorange40: '#633b0a',
		lightorange20: '#311d05',
		// GREEN
		green100: '#3bc735',
		darkgreen80: '#62d25d',
		darkgreen60: '#89dd86',
		darkgreen40: '#b1e9ae',
		darkgreen20: '#d8f4d7',
		lightgreen80: '#2f9f2a',
		lightgreen60: '#237720',
		lightgreen40: '#185015',
		lightgreen20: '#0c280b',
	},
	fontSizes: {
		xxs: '0.625rem'
	},
	textStyles: {
		roman: {
			fontFamily: 'Times New Roman, serif',
		},
	},
	shadows: {
		outerGlow: '0px 0px 25px #999',
		calAlertShadow: '0px 0px 99px 88px rgba(0,0,0,1)',
		embedGlow: `0px 0px 7px ${BITCOIN_ORANGE}`,
	},
	breakpoints: {
		xxxs: '280px',
		xxs: '320px',
		xs: '380px',
		sm: '420px',
		smd: '600px',
		md: '720px',
		lg: '960px',
		xl: '1024px',
		xxl: '1280px',
	},
	components: {
		Switch: defineMultiStyleConfig({
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			baseStyle: definePartsStyle((props: any) => ({
				track: {
					bg: props.colorMode === 'light' ? 'rgba(0,0,0,0.5)' : 'rgba(255,255,255,0.5)',
					_checked: {
						bg: BITCOIN_ORANGE,
					},
				}
			})),
		}),
	},
})
