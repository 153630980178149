import { useState } from 'react'
import {
	Box,
	Slider,
	SliderMark,
	SliderTrack,
	SliderFilledTrack,
	SliderThumb,
	chakra,
	shouldForwardProp,
	useColorModeValue,
} from '@chakra-ui/react'
import { motion, isValidMotionProp } from 'framer-motion'
import { DragHandleIcon } from '@chakra-ui/icons'
import { BITCOIN_ORANGE } from '../../../../constants'

interface FutureSearchSliderProps {
	blockTimeMs: number
	// eslint-disable-next-line no-unused-vars
	onChange: (value: number) => void
}

const MotionBox = chakra(motion.div, {
	shouldForwardProp: (prop) => isValidMotionProp(prop) || shouldForwardProp(prop),
})

export const FutureSearchSlider = ({
	blockTimeMs,
	onChange,
}: FutureSearchSliderProps) => {
	const [sliding, setSliding] = useState(false)
	const bg = useColorModeValue('white', 'black')
	const color = useColorModeValue('black', 'white')
	const colorAlt2 = useColorModeValue('rgba(0,0,0,0.7)', 'rgba(255,255,255,0.7)')
	const labelStyles = {
		mt: '15px',
		ml: '-12px',
		fontSize: 'xs',
		color: colorAlt2,
	}

	const handleChangeStart = () => {
		setSliding(true)
	}
	const handleChangeEnd = () => {
		setSliding(false)
	}

	return (
		<Slider
			min={480000}
			max={720000}
			defaultValue={blockTimeMs}
			onChange={(value) => onChange(value)}
			onChangeStart={() => handleChangeStart()}
			onChangeEnd={() => handleChangeEnd()}
		>
			<SliderMark value={480000} {...labelStyles}>
				<b>8</b>m
			</SliderMark>
			<SliderMark value={540000} {...labelStyles}>
				<b>9</b>m
			</SliderMark>
			<SliderMark value={600000} {...labelStyles}>
				<b>10</b>m
			</SliderMark>
			<SliderMark value={660000} {...labelStyles}>
				<b>11</b>m
			</SliderMark>
			<SliderMark value={720000} {...labelStyles}>
				<b>12</b>m
			</SliderMark>

			<SliderTrack
				h={{ base: '2px', md: '3px' }}
				bg="#777"
			>
				<SliderFilledTrack
					bg="whiteAlpha.500"
					transition="all 0.45s ease"
				/>
			</SliderTrack>
			<SliderThumb
				w={6}
				h={7}
				borderRadius="4px"
				borderWidth="3px"
				borderColor={BITCOIN_ORANGE}
				bg={bg}
				outline="none"
				boxShadow="none"
				transition="borderColor 0.25s ease"
				_hover={{
					borderColor: BITCOIN_ORANGE,
				}}
				_focus={{
					outline: 'none',
					boxShadow: 'none',
				}}
			>
				<MotionBox
					transform="translateY(-2px)"
					transformOrigin="center"
					animate={{
						opacity: sliding ? [1, 1] : [1, 0.3, 1],
					}}
					// @ts-ignore
					transition={{
						duration: 2.2,
						ease: 'linear',
						repeat: Infinity,
						repeatType: 'loop',
					}}
				>
					<Box
						as={DragHandleIcon}
						color={color}
						h={3}
					/>
				</MotionBox>
			</SliderThumb>
		</Slider>
	)
}
