import { useEffect } from 'react'
import useSound from 'use-sound'

interface SoundProps {
	playSound: boolean
	// eslint-disable-next-line no-unused-vars
	setPlaySound: (x: boolean) => void
	stopSound?: boolean
	// eslint-disable-next-line no-unused-vars
	setStopSound?: (x: boolean) => void
	playSample?: boolean
	// eslint-disable-next-line no-unused-vars
	setPlaySample?: (x: boolean) => void
}

export const DropSound = ({ playSound, setPlaySound }: SoundProps) => {
	const [playDropHeavy] = useSound('audio/drop-heavy.mp3', {
		playbackRate: 0.675,
		volume: 0.25,
	})
	const [playDropCave] = useSound('audio/drop-cave.mp3', {
		volume: 1,
	})

	useEffect(() => {
		if (playSound) {
			playDropHeavy()
			playDropCave()
			setPlaySound(false)
		}
	}, [playSound])

	return null
}

export const GongSound = ({ playSound, setPlaySound }: SoundProps) => {
	const [playGong] = useSound('audio/gong.mp3')

	useEffect(() => {
		if (playSound) {
			playGong()
			setPlaySound(false)
		}
	}, [playSound])

	return null
}

export const DoorbellSound = ({ playSound, setPlaySound }: SoundProps) => {
	const [playDoorbell] = useSound('audio/door-bell.mp3', {
		volume: 0.55,
	})

	useEffect(() => {
		if (playSound) {
			playDoorbell()
			setPlaySound(false)
		}
	}, [playSound])
	return null
}

export const BellSound = ({ playSound, setPlaySound }: SoundProps) => {
	const [playBell] = useSound('audio/bell-clean.mp3')

	useEffect(() => {
		if (playSound) {
			playBell()
			setPlaySound(false)
		}
	}, [playSound])

	return null
}

export const WhooshSound = ({ playSound, setPlaySound }: SoundProps) => {
	const [playWhoosh] = useSound('audio/whoosh.mp3', {
		volume: 0.75,
	})

	useEffect(() => {
		if (playSound) {
			playWhoosh()
			setPlaySound(false)
		}
	}, [playSound])

	return null
}

export const AlarmClockSound = ({
	playSound,
	setPlaySound,
	stopSound,
	setStopSound,
	playSample,
	setPlaySample,
}: SoundProps) => {
	const [playAlarmClock, { stop: stopAlarmClock }] = useSound(
		'audio/alarm-clock3.mp3',
		{ volume: 0.85 },
	)
	const [playAlarmClockSample] = useSound(
		'audio/alarm-clock3-sample.mp3',
		{ volume: 0.65 },
	)

	useEffect(() => {
		if (playSound) {
			playAlarmClock()
			setPlaySound(false)
		}
		if (stopSound && setStopSound) {
			stopAlarmClock()
			setStopSound(false)
		}
	}, [playSound, stopSound])

	useEffect(() => {
		if (playSample && setPlaySample) {
			playAlarmClockSample()
			setPlaySample(false)
		}
	}, [playSample])

	return null
}

export const AlarmGongSound = ({
	playSound,
	setPlaySound,
	stopSound,
	setStopSound,
	playSample,
	setPlaySample,
}: SoundProps) => {
	const [playGong, { stop: stopGong }] = useSound(
		'audio/alarm-gong-fade.mp3',
		{ volume: 0.8 },
	)
	const [playGongSample] = useSound(
		'audio/alarm-gong-fade-sample.mp3',
		{ volume: 0.7 },
	)

	useEffect(() => {
		if (playSound) {
			playGong()
			setPlaySound(false)
		}
		if (stopSound && setStopSound) {
			stopGong()
			setStopSound(false)
		}
	}, [playSound, stopSound])

	useEffect(() => {
		if (playSample && setPlaySample) {
			playGongSample()
			setPlaySample(false)
		}
	}, [playSample])

	return null
}

export const AlarmGrandfatherClockSound = ({
	playSound,
	setPlaySound,
	stopSound,
	setStopSound,
	playSample,
	setPlaySample,
}: SoundProps) => {
	const [playGrandfather, { stop: stopGrandfather }] = useSound(
		'audio/alarm-grandfather-clock-fade.mp3',
		{ volume: 0.85 },
	)
	const [playGrandfatherSample] = useSound(
		'audio/alarm-grandfather-clock-fade-sample.mp3',
		{ volume: 0.65 },
	)

	useEffect(() => {
		if (playSound) {
			playGrandfather()
			setPlaySound(false)
		}
		if (stopSound && setStopSound) {
			stopGrandfather()
			setStopSound(false)
		}
	}, [playSound, stopSound])

	useEffect(() => {
		if (playSample && setPlaySample) {
			playGrandfatherSample()
			setPlaySample(false)
		}
	}, [playSample])

	return null
}

export const AlarmFrankieSound = ({
	playSound,
	setPlaySound,
	stopSound,
	setStopSound,
	playSample,
	setPlaySample,
}: SoundProps) => {
	const [playFrankie, { stop: stopFrankie }] = useSound(
		'audio/alarm-frankie.mp3',
		{ volume: 0.7 },
	)
	const [playFrankieSample] = useSound(
		'audio/alarm-frankie-sample.mp3',
		{ volume: 0.5 },
	)

	useEffect(() => {
		if (playSound) {
			playFrankie()
			setPlaySound(false)
		}
		if (stopSound && setStopSound) {
			stopFrankie()
			setStopSound(false)
		}
	}, [playSound, stopSound])

	useEffect(() => {
		if (playSample && setPlaySample) {
			playFrankieSample()
			setPlaySample(false)
		}
	}, [playSample])

	return null
}
