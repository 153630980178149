import { atom } from 'recoil'

import type { HashrateAverages } from '../models'

export const hashrateAveragesState = atom({
	key: 'hashrateAverages',
	default: {
		current: 0,
		yesterday: 0,
		sevenDay: 0,
		yesterdaySevenDay: 0,
		twoWeek: 0,
		yesterdayTwoWeek: 0,
		month: 0,
		yesterdayMonth: 0,
		threeMonth: 0,
		yesterdayThreeMonth: 0,
	} as HashrateAverages,
})
