import {
	Text,
	chakra,
	shouldForwardProp,
	useColorModeValue,
} from '@chakra-ui/react'
import { FormattedMessage } from 'react-intl'
import { useRecoilValue } from 'recoil'
import { motion, isValidMotionProp } from 'framer-motion'

import {
	pastBlocksState,
	past24IntroStartState,
	circlesBreakpointState,
} from '../../../../state'
import { Label } from '../../../shared'
import { BITCOIN_ORANGE } from '../../../../constants'
import { getBreakpointValue } from '../../../../utils'
import { primaryDataFontSize } from './dataComponents.constants'

const MotionBox = chakra(motion.div, {
	shouldForwardProp: (prop) => isValidMotionProp(prop) || shouldForwardProp(prop),
})

export const DataLast24Hrs = () => {
	const pastBlocks = useRecoilValue(pastBlocksState)
	const past24IntroStart = useRecoilValue(past24IntroStartState)
	const circlesBreakpoint = useRecoilValue(circlesBreakpointState)
	const fontSize = getBreakpointValue(primaryDataFontSize, circlesBreakpoint)
	const color = useColorModeValue('black', 'white')
	const labelColor = useColorModeValue('rgba(0,0,0,0.7)', 'rgba(255,255,255, 0.7)')

	const TargetBlocks = () => (
		<span style={{ color: labelColor, fontWeight: 'normal' }}>/ 144</span>
	)

	return (
		<>
			<Label>
				<FormattedMessage id="data.last_24hrs.title" />
			</Label>

			{past24IntroStart && (
				<Text
					lineHeight={fontSize}
					my={1}
					color={color}
					fontSize={fontSize}
					fontWeight="bold"
				>
					{pastBlocks.length} <TargetBlocks />
				</Text>
			)}

			{!past24IntroStart && (
				<MotionBox
					color={BITCOIN_ORANGE}
					animate={{
						opacity: [1, 0.3],
					}}
					// @ts-ignore
					transition={{
						duration: 0.5,
						ease: 'linear',
						repeat: Infinity,
						repeatType: 'loop',
					}}
				>
					<Text fontSize={fontSize} fontWeight="bold" my="1px">0 <TargetBlocks /></Text>
				</MotionBox>
			)}

			<Label color={labelColor} fontWeight="semibold">
				<FormattedMessage id="data.last_24hrs.blocks" />
			</Label>
		</>
	)
}
