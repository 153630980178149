import {
	Flex,
	Box,
	Button,
	chakra,
	shouldForwardProp,
} from '@chakra-ui/react'
import { motion, isValidMotionProp } from 'framer-motion'
import { useRecoilValue } from 'recoil'

import { useAppSelector, useAppDispatch } from '../../../hooks'
import {
	circlesBreakpointState,
	editingBlockHeightState,
} from '../../../state'
import { BITCOIN_ORANGE } from '../../../constants'
import { getBreakpointValue } from '../../../utils'
import { setUserAlarmBlock } from '../settings/settingsReducer'

interface SearchButtonProps {
	onClick: () => void
	alarmTriggered: boolean
	alarmBlocksRemaining: number
	stopAlarm: () => void
}

const MotionBox = chakra(motion.div, {
	shouldForwardProp: (prop) => isValidMotionProp(prop) || shouldForwardProp(prop),
})

export const TCAlarmButton = ({
	onClick,
	alarmTriggered,
	alarmBlocksRemaining,
	stopAlarm,
	...rest
}: SearchButtonProps) => {
	const dispatch = useAppDispatch()
	const { userAlarmBlock } = useAppSelector((state) => state.settings)
	const circlesBreakpoint = useRecoilValue(circlesBreakpointState)
	const editingBlockHeight = useRecoilValue(editingBlockHeightState)

	const interactionStyle = {
		borderColor: BITCOIN_ORANGE,
		color: BITCOIN_ORANGE,
	}

	const color = alarmTriggered
		? 'red100'
		: userAlarmBlock
			? 'green100'
			: 'white'

	const alarmBlocksRemainingString = alarmBlocksRemaining > 9
		? '9+'
		: alarmBlocksRemaining


	const responsiveButtonLabelSize = getBreakpointValue({ base: '11px', md: '12px' }, circlesBreakpoint)
	const buttonHeight = getBreakpointValue({ base: '30px', md: '38px' }, circlesBreakpoint)
	const buttonLineHeight = getBreakpointValue({ base: '26px', md: '36px' }, circlesBreakpoint)
	const buttonWrapFlex = getBreakpointValue({ base: '1 1 50%', md: '1 1 20%' }, circlesBreakpoint)
	const remainingPosition = getBreakpointValue({ base: '19px', sm: '20px', lg: '22px', xl: '24px' }, circlesBreakpoint)
	const remainingFontSize = getBreakpointValue({ base: 'xxs', lg: 'xs' }, circlesBreakpoint)

	const handleButtonClick = () => {
		if (alarmTriggered) {
			dispatch(setUserAlarmBlock(null))
			stopAlarm()
		}
		if (!alarmTriggered) {
			onClick()
		}
	}

	return (
		<Box
			className="tc-alarm-button-wrap"
			flex={buttonWrapFlex}
			onClick={handleButtonClick}
			cursor="pointer"
			role="group"
			pos="relative"
			zIndex={1015}
			{...rest}
		>
			<Button
				className="tc-gallery-button"
				w="100%"
				size="sm"
				variant="outline"
				bg="black"
				fontSize={responsiveButtonLabelSize}
				height={buttonHeight}
				lineHeight={buttonLineHeight}
				textTransform="uppercase"
				py="0"
				px="12px"
				color={color}
				borderColor={color}
				borderWidth={2}
				borderRadius="md"
				disabled={editingBlockHeight}
				_active={interactionStyle}
				_hover={interactionStyle}
				_groupHover={interactionStyle}
			>
				{alarmBlocksRemaining > 0 && (
					<Flex
						align="center"
						justify="center"
						fontSize={remainingFontSize}
						fontWeight="bold"
						pos="absolute"
						top="4px"
						right="4px"
						w={remainingPosition}
						h={remainingPosition}
						bgColor="black"
						color="white"
						borderWidth={1}
						borderColor="white"
						borderRadius="50%"
						letterSpacing="-0.1em"
					>
						{alarmBlocksRemainingString}
					</Flex>
				)}
				{alarmTriggered && (
					<MotionBox
						animate={{
							opacity: [1, 0.05],
						}}
						// @ts-ignore
						transition={{
							duration: 0.325,
							ease: 'linear',
							repeat: Infinity,
							repeatType: 'loop',
						}}
					>
						Block Alarm
					</MotionBox>
				)}
				{!alarmTriggered && (
					<>Block Alarm</>
				)}
			</Button>
		</Box>
	)
}
