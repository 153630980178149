export const txThreshold = {
	mild: 99999,
	medium: 199999,
	hot: 339999,
	spicy: 499999,
	superHot: 999999,
}

export const vBytesThreshold = {
	mild: 3499,
	medium: 5999,
	hot: 8499,
	spicy: 10999,
	superHot: 14999,
}

export const mempoolBlocksThreshold = {
	mild: 99,
	medium: 199,
	hot: 299,
	spicy: 399,
	superHot: 499,
}

export const mempoolUsageThreshold = {
	warm: 199999999,
	mild: 219999999,
	medium: 239999999,
	hot: 259999999,
	spicy: 279999999,
	superHot: 299999999,
}

export const feeThreshold = {
	mild: 24,
	medium: 49,
	hot: 79,
	spicy: 129,
	superHot: 199,
}

export const blurInAnimation = 'blurIn 4s ease-in'
export const blurInFastAnimation = 'blurIn 2s ease-in'
