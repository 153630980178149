// NEW CURRENCY UPDATE HERE
export type UserCurrency = 'usd' | 'ars' | 'aud' | 'brl' | 'cad' | 'chf' | 'cny' | 'eur' | 'gbp' | 'inr' | 'jpy' | 'mxn' | 'ngn' | 'pln' | 'rub' | 'sek' | 'thb' | 'try' | 'vnd' | 'zar'

export interface UserCurrencyMap {
    [key: string]: string
}

// NEW CURRENCY UPDATE HERE
export const CURRENCY_LABEL_MAP: UserCurrencyMap = {
	// default
	usd: 'USD',

	// conversions
	ars: 'ARS',
	aud: 'AUD',
	brl: 'BRL',
	cad: 'CAD',
	chf: 'CHF',
	cny: 'CNY',
	eur: 'EUR',
	gbp: 'GBP',
	inr: 'INR',
	jpy: 'JPY',
	mxn: 'MXN',
	ngn: 'NGN',
	pln: 'PLN',
	rub: 'RUB',
	sek: 'SEK',
	thb: 'THB',
	try: 'TRY',
	vnd: 'VND',
	zar: 'ZAR',
}

// NEW CURRENCY UPDATE HERE
export const CURRENCY_SYMBOL_MAP: UserCurrencyMap = {
	// default
	usd: '$',

	// conversions
	ars: '$',
	aud: '$',
	brl: 'R$',
	cad: '$',
	chf: '₣',
	cny: '¥',
	eur: '€',
	gbp: '£',
	inr: '₹',
	jpy: '¥',
	mxn: '$',
	ngn: '₦',
	pln: 'zł',
	rub: '₽',
	sek: 'kr',
	thb: '฿',
	try: '₺',
	vnd: '₫',
	zar: 'R',
}
