import {
	Flex,
	Text,
	CloseButton,
	Modal,
	ModalHeader,
	ModalBody,
	ModalContent,
	useColorModeValue,
} from '@chakra-ui/react'
import { FormattedMessage } from 'react-intl'

import { AnimatedSunIcon } from './CalendarAlerts.components'
import { GMAlertsButton } from './GMAlertsButton'
import { ModalOverlay } from '../../shared'
import { useAppSelector } from '../../../hooks'
import { BITCOIN_ORANGE } from '../../../constants'
import { rtlLocales } from '../../../lang/messages'

interface GmAlertProps {
	message: string
	isOpen: boolean
	onOpen: () => void
	onClose: () => void
}

export const GMAlert = ({
	message,
	isOpen,
	onOpen,
	onClose,
}: GmAlertProps) => {
	const { userLocale } = useAppSelector((state) => state.settings)
	const direction = rtlLocales.includes(userLocale) ? 'rtl' : undefined
	const bg = useColorModeValue('white', 'black')
	const color = useColorModeValue('black', 'white')

	return (
		<>
			<GMAlertsButton onOpen={onOpen} />

			<Modal
				key="alarm"
				isOpen={isOpen}
				onClose={onClose}
				isCentered
				motionPreset="slideInBottom"
				size="sm"
			>
				<ModalOverlay />

				<ModalContent
					bg={bg}
					borderWidth={1}
					borderColor={color}
					color={color}
					borderRadius="lg"
					zIndex={3334}
					dir={direction}
				>
					<ModalHeader p={1}>
						<Flex align="center" justify="space-between">
							<AnimatedSunIcon />

							<CloseButton
								onClick={onClose}
								_hover={{ color: BITCOIN_ORANGE }}
							/>
						</Flex>
					</ModalHeader>

					<ModalBody>
						<Flex
							direction="column"
							align="center"
							flexGrow={1}
							p={2}
							pb={10}
						>
							{message && (
								<Text
									fontSize={{ base: 'sm', md: 'lg' }}
									fontWeight="extrabold"
									lineHeight={{ base: 'none', md: 1.15 }}
									textTransform="uppercase"
									color={color}
									align="center"
								>
									<FormattedMessage id={message} />
								</Text>
							)}
						</Flex>
					</ModalBody>
				</ModalContent>
			</Modal>
		</>
	)
}
