import { Flex, Text, useColorModeValue } from '@chakra-ui/react'
import { useRecoilValue } from 'recoil'

import { useAppSelector } from '../../../hooks'
import { circlesBreakpointState } from '../../../state'
import { Label } from '../../shared'
import { feeThreshold, STYLE_TRANSITION_ALL } from '../../../constants'
import { getBreakpointValue } from '../../../utils'
import { rtlLocales } from '../../../lang/messages'
import { primaryDataFontSize } from './data-components/dataComponents.constants'

interface FeeSectionProps {
	title: string
	fee?: number
	borderColor: string
	purgeBelowFee?: number
	isPurging: boolean
}

export const getFeeColor = (fee: number, thisPurging = false) => {
	const mild = useColorModeValue('lightred20', 'darkred20')
	const medium = useColorModeValue('lightred40', 'darkred40')
	const hot = useColorModeValue('lightred60', 'darkred60')
	const spicy = useColorModeValue('lightred80', 'darkred80')
	const textColor = useColorModeValue('black', 'white')

	if (thisPurging) return 'red100'
	if (fee > feeThreshold.superHot) return 'red100'
	if (fee > feeThreshold.spicy) return spicy
	if (fee > feeThreshold.hot) return hot
	if (fee > feeThreshold.medium) return medium
	if (fee > feeThreshold.mild) return mild
	return textColor
}

export const FeeSection = ({
	title,
	fee = 0,
	borderColor,
	purgeBelowFee = 0,
	isPurging,
}: FeeSectionProps) => {
	// @ts-ignore
	const { userLocale } = useAppSelector(({ settings }) => settings)
	const direction = rtlLocales.includes(userLocale) ? 'rtl' : undefined
	const circlesBreakpoint = useRecoilValue(circlesBreakpointState)
	const fontSize = getBreakpointValue(primaryDataFontSize, circlesBreakpoint)
	const thisPurging = purgeBelowFee > 0
	const feeText = thisPurging
		? purgeBelowFee
		: fee > 0
			? fee
			: '-'
	const memoFeeFontWeight = getBreakpointValue({ base: 'bold', md: 'semibold' }, circlesBreakpoint)
	const feeFontWeight = thisPurging
		? 'bold'
		: memoFeeFontWeight
	const labelColor = useColorModeValue('black', 'white')
	const feeLabelColor = thisPurging ? 'red100' : labelColor
	const labelWeight = thisPurging ? 'bold' : 'normal'
	const flex = isPurging ? '0 0 33.33%' : '0 0 50%'

	const purgeArrowStyle = { fontWeight: 'normal', paddingRight: '1px' }

	return (
		<Flex
			flex={flex}
			direction="row"
			justify="center"
			align="center"
			gap="6px"
			pt="12px"
			borderLeftColor={borderColor}
			_first={{
				borderLeft: 'none',
			}}
			transition={STYLE_TRANSITION_ALL}
			dir={direction}
		>
			<Label fontWeight={labelWeight} color={feeLabelColor}>
				{title}:
			</Label>

			<Text
				color={getFeeColor(fee, thisPurging)}
				fontSize={fontSize}
				fontWeight={feeFontWeight}
				lineHeight="normal"
				verticalAlign="center"
			>
				{thisPurging && <span style={purgeArrowStyle}>{'<'}</span>}{feeText}
			</Text>
		</Flex>
	)
}
