import {
	EPOCH_BLOCKS,
	SUBSIDY_MAP,
	SATS_PER_BITCOIN,
	TOTAL_BITCOIN_SUPPLY,
} from '../constants/bitcoin.constants'
import {
	BoltSymbol,
	BitkoSatsSymbol,
	BarbellSatsSymbol,
	SATS_SYMBOL_OPTIONS,
	type SatsSymbol,
} from '../components/shared'

export const getBitcoinEpoch = (blockHeight: number) => {
	if (blockHeight === 0) return 1
	return Math.floor((blockHeight / EPOCH_BLOCKS)) + 1
}

export const getBlocksToHalving = (blockHeight: number) => {
	const epoch = getBitcoinEpoch(blockHeight)
	const nextHalving = epoch * EPOCH_BLOCKS
	return nextHalving - blockHeight
}

export const getNextHalvingBlock = (blockHeight: number) => {
	const epoch = getBitcoinEpoch(blockHeight)
	const nextHalving = epoch * EPOCH_BLOCKS
	return nextHalving
}

export const getMinerSubsidy = (epoch: number) => {
	return SUBSIDY_MAP[epoch - 1]
}

export const getSupplyIssued = (blockHeight: number) => {
	const epoch = getBitcoinEpoch(blockHeight)
	let totalReward = 50

	for (let i = 1; i <= epoch; i++) {
		const epochSubsidy = SUBSIDY_MAP[i - 1]
		const epochTotalReward = EPOCH_BLOCKS * epochSubsidy

		if (i === epoch) {
			const currentEpochBlocks = blockHeight - (EPOCH_BLOCKS * (epoch - 1))
			const currentEpochTotalReward = currentEpochBlocks * epochSubsidy

			totalReward += currentEpochTotalReward

			return {
				supplyIssued: totalReward,
				supplyIssuedPercent: totalReward / TOTAL_BITCOIN_SUPPLY,
			}
		}

		totalReward += epochTotalReward
	}
}

export const getAvgBlockTime = (timeMs: number) => {
	const timeRemainder = timeMs % 1000
	const roundedTime = timeRemainder ? (timeMs - timeRemainder) : timeMs
	const totalSeconds = roundedTime / 1000
	const totalMinutes = Math.floor(totalSeconds / 60)
	const seconds = totalSeconds % 60
	const minutes = totalMinutes % 60
	const hours = Math.floor(totalMinutes / 60)
	const hoursString = hours > 0 ? `${hours}h` : ''
	const minutesString = minutes > 0 ? `${minutes}m` : ''
	const secondsString = seconds > 0 ? `${seconds.toFixed(0)}s` : ''
	const blockTime = `${hoursString}${hoursString && minutesString ? ' ' : ''}${minutesString}${(hoursString || minutesString) && secondsString ? ' ' : ''}${secondsString}`.trim()

	return blockTime
}

export const getMoscowTime = (usdPrice: number) => {
	return Math.round((SATS_PER_BITCOIN / usdPrice))
}

export const satsToBitcoin = (sats: number) => {
	return sats / SATS_PER_BITCOIN
}

export const getUserSatsSymbol = (symbol: SatsSymbol, bitkoSize?: string) => {
	switch (symbol) {
		case SATS_SYMBOL_OPTIONS.bolt:
			return <BoltSymbol width="12px" transform="translateY(2px) translateX(1px)" />
		case SATS_SYMBOL_OPTIONS.bitko:
			return <BitkoSatsSymbol width={bitkoSize ?? '10px'} transform="translateY(1px)" />
		case SATS_SYMBOL_OPTIONS.barbell:
			return <BarbellSatsSymbol />
		default:
			return <span>{SATS_SYMBOL_OPTIONS.sats}</span>
	}
}

export const getTxsLabel = (txs: number, locale: string, emptyString: string) => {
	const txsAbbr = locale === 'ar'
		? ' عملية'
		: txs === 1
			? `TX (${emptyString})`
			: 'TXS'
	return txsAbbr
}
