import type { FC, PropsWithChildren } from 'react'
import {
	Flex,
	Box,
	Link,
	ListItem,
	Image,
	type ListItemProps,
} from '@chakra-ui/react'

import { BITCOIN_ORANGE } from '../../constants'

interface IconLiProps {
	imageSrc: string
	link: string
	title: string
	description: string
}

type LIProps = ListItemProps & PropsWithChildren

export const LI: FC<LIProps> = ({ children, ...rest }) => {
	return (
		<ListItem fontSize="sm" lineHeight="none" mb={3} {...rest}>
			{children}
		</ListItem>
	)
}

export const IconLi = ({
	imageSrc,
	link,
	title,
	description,
}: IconLiProps) => {

	return (
		<LI mb="14px">
			<Flex align="flex-start" gap={3}>
				<Link
					color={BITCOIN_ORANGE}
					fontWeight="semibold"
					href={link}
					flexShrink={0}
					isExternal
				>
					<Image
						src={imageSrc}
						alt={title}
						w={7}
						h={7}
					/>
				</Link>
				<Box lineHeight={1.3}>
					<Link
						color={BITCOIN_ORANGE}
						fontWeight="semibold"
						href={link}
						isExternal
					>
						{title}
					</Link>:&nbsp;
					{description}
				</Box>
			</Flex>
		</LI>
	)
}