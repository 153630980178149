import {
	Flex,
	Box,
	Text,
	useColorModeValue,
	type ResponsiveValue,
} from '@chakra-ui/react'
import { FormattedMessage } from 'react-intl'

import { useRecoilValue } from 'recoil'
import {
	maxMempoolState,
	mempoolSizeState,
	mempoolUsageState,
	mempoolBlocksState,
	txVBytesPerSecondState,
	circlesBreakpointState,
} from '../../../../state'

import { Label } from '../../../shared'
import {
	vBytesThreshold,
	txThreshold,
	mempoolBlocksThreshold,
	mempoolUsageThreshold,
	STYLE_TRANSITION_ALL,
} from '../../../../constants'
import { abbreviateMempoolSize, getBreakpointValue } from '../../../../utils'
import { primaryDataFontSize } from '../data-components/dataComponents.constants'

interface MempoolSectionProps {
	sidebar?: boolean
}

const Usage = () => {
	const mempoolUsage = useRecoilValue(mempoolUsageState)
	
	const textColor = useColorModeValue('black', 'white')
	const mild = useColorModeValue('lightred20', 'darkred20')
	const medium = useColorModeValue('lightred40', 'darkred40')
	const hot = useColorModeValue('lightred60', 'darkred60')
	const spicy = useColorModeValue('lightred80', 'darkred80')

	const usageMempool = abbreviateMempoolSize(mempoolUsage)

	const getUsageColor = () => {
		if (mempoolUsage > mempoolUsageThreshold.superHot) return 'red100'
		if (mempoolUsage > mempoolUsageThreshold.spicy) return 'red100'
		if (mempoolUsage > mempoolUsageThreshold.hot) return spicy
		if (mempoolUsage > mempoolUsageThreshold.medium) return hot
		if (mempoolUsage > mempoolUsageThreshold.mild) return medium
		if (mempoolUsage > mempoolUsageThreshold.warm) return mild
		return textColor
	}

	const color = getUsageColor()

	return (
		<Text fontWeight="bold" color={color}>{usageMempool}</Text>
	)
}

const Inflow = () => {
	const circlesBreakpoint = useRecoilValue(circlesBreakpointState)
	const txVBytesPerSecondRecoil = useRecoilValue(txVBytesPerSecondState)

	const fontSize = getBreakpointValue(primaryDataFontSize, circlesBreakpoint)
	const responsiveSidesFontWeight = getBreakpointValue({ base: 'semibold', md: 'bold' }, circlesBreakpoint)
	const mild = useColorModeValue('lightred20', 'darkred20')
	const medium = useColorModeValue('lightred40', 'darkred40')
	const hot = useColorModeValue('lightred60', 'darkred60')
	const spicy = useColorModeValue('lightred80', 'darkred80')
	const textColor = useColorModeValue('black', 'white')

	const getVBytesColor = () => {
		if (txVBytesPerSecondRecoil > vBytesThreshold.superHot) return 'red100'
		if (txVBytesPerSecondRecoil > vBytesThreshold.spicy) return spicy
		if (txVBytesPerSecondRecoil > vBytesThreshold.hot) return hot
		if (txVBytesPerSecondRecoil > vBytesThreshold.medium) return medium
		if (txVBytesPerSecondRecoil > vBytesThreshold.mild) return mild
		return textColor
	}

	const color = getVBytesColor()

	return (
		<Text
			color={color}
			fontSize={fontSize}
			fontWeight={responsiveSidesFontWeight}
			lineHeight="none"
			my={1}
		>
			{txVBytesPerSecondRecoil}
		</Text>
	)
}

const UnconfirmedTxs = () => {
	const circlesBreakpoint = useRecoilValue(circlesBreakpointState)
	const mempoolSize = useRecoilValue(mempoolSizeState)

	const fontSize = getBreakpointValue(primaryDataFontSize, circlesBreakpoint)
	const mild = useColorModeValue('lightred20', 'darkred20')
	const medium = useColorModeValue('lightred40', 'darkred40')
	const hot = useColorModeValue('lightred60', 'darkred60')
	const spicy = useColorModeValue('lightred80', 'darkred80')
	const textColor = useColorModeValue('black', 'white')

	const getTxsColor = () => {
		if (mempoolSize > txThreshold.superHot) return 'red100'
		if (mempoolSize > txThreshold.spicy) return spicy
		if (mempoolSize > txThreshold.hot) return hot
		if (mempoolSize > txThreshold.medium) return medium
		if (mempoolSize > txThreshold.mild) return mild
		return textColor
	}

	const color = getTxsColor()

	return (
		<Box my={1}>
			<Text
				color={color}
				fontSize={fontSize}
				fontWeight="bold"
				lineHeight="none"
			>
				{mempoolSize}
			</Text>
		</Box>
	)
}

const Depth = () => {
	const circlesBreakpoint = useRecoilValue(circlesBreakpointState)
	const mempoolBlocksRecoil = useRecoilValue(mempoolBlocksState)
	
	const fontSize = getBreakpointValue(primaryDataFontSize, circlesBreakpoint)
	const responsiveSidesFontWeight = getBreakpointValue({ base: 'semibold', md: 'bold' }, circlesBreakpoint)
	const mild = useColorModeValue('lightred20', 'darkred20')
	const medium = useColorModeValue('lightred40', 'darkred40')
	const hot = useColorModeValue('lightred60', 'darkred60')
	const spicy = useColorModeValue('lightred80', 'darkred80')
	const textColor = useColorModeValue('black', 'white')

	const getMempoolBlocksColor = () => {
		if (mempoolBlocksRecoil > mempoolBlocksThreshold.superHot) return 'red100'
		if (mempoolBlocksRecoil > mempoolBlocksThreshold.spicy) return spicy
		if (mempoolBlocksRecoil > mempoolBlocksThreshold.hot) return hot
		if (mempoolBlocksRecoil > mempoolBlocksThreshold.medium) return medium
		if (mempoolBlocksRecoil > mempoolBlocksThreshold.mild) return mild
		return textColor
	}

	const color = getMempoolBlocksColor()

	return (
		<Text
			color={color}
			fontSize={fontSize}
			fontWeight={responsiveSidesFontWeight}
			lineHeight="none"
		>
			~{mempoolBlocksRecoil}
		</Text>
	)
}

export const MempoolSection = ({ sidebar = false }: MempoolSectionProps) => {
	const circlesBreakpoint = useRecoilValue(circlesBreakpointState)
	const maximumMempool = useRecoilValue(maxMempoolState)
	const mempoolUsage = useRecoilValue(mempoolUsageState)
	const isPurging = mempoolUsage > maximumMempool
	const maxMempool = abbreviateMempoolSize(maximumMempool)

	const bg = useColorModeValue('white', 'black')
	const borderColor = useColorModeValue('rgba(0,0,0,0.3)', 'rgba(255,255,255,0.3)')
	const labelColor = useColorModeValue('rgba(0,0,0,0.7)', 'rgba(255,255,255,0.7)')
	const mempoolBorderC = useColorModeValue('lightred80', 'lightred60')
	const mempoolLabelBorderC = useColorModeValue('lightred80', 'lightred60')
	const mempoolBorderColor = isPurging
		? mempoolBorderC
		: borderColor
	const mempoolLabelBorderColor = isPurging
		? mempoolLabelBorderC
		: borderColor
	const mempoolResponsivePadding = getBreakpointValue({ base: '0', lg: 6 }, circlesBreakpoint)
	const sideW = getBreakpointValue({ base: '30%', md: '31%' }, circlesBreakpoint)
	const responsivePt = getBreakpointValue({ base: 3, xl: 4 }, circlesBreakpoint)
	const responsiveMempoolUsageFontSize = getBreakpointValue({ base: '12px', md: '13px', lg: '14px', xxl: '17px', xxxl: 'lg' }, circlesBreakpoint)
	const responsiveSectionLabelSize = getBreakpointValue({ base: '9px', xs: '10px', lg: '11px', xl: '12px', xxxl: '13px' }, circlesBreakpoint)
	const memoSectionGap = getBreakpointValue({ base: 2, xxxl: 3 }, circlesBreakpoint)
	const mempoolJustify = getBreakpointValue({ base: 'center', md: 'flex-start' }, circlesBreakpoint) as ResponsiveValue<'center' | 'flex-start'>

	const mempoolLabelColor = isPurging ? 'red100' : labelColor
	const sectionGap = sidebar
		? 3
		: memoSectionGap
	const height = 4

	return (
		<Flex
			className="mempool-section"
			pos="relative"
			zIndex={3}
			direction="column"
			align="center"
			w="100%"
			gap={sectionGap}
		>
			<Flex
				alignSelf="stretch"
				pos="relative"
				w="100%"
				justify={mempoolJustify}
				align="flex-start"
				px={mempoolResponsivePadding}
			>
				<Flex
					w={sideW}
					direction="column"
					align="center"
					alignSelf="stretch"
					borderColor={mempoolBorderColor}
					borderTopWidth={1}
					transition={STYLE_TRANSITION_ALL}
					pt={responsivePt}
				>
					<Flex direction="column" align="center">
						<Label>
							<FormattedMessage
								id="mempool.inflow"
							/>
						</Label>
						
						<Inflow />

						<Label
							color={labelColor}
							fontWeight="semibold"
							textTransform="none"
						>
							vB/S
						</Label>
					</Flex>
				</Flex>

				<Flex
					w="46%"
					flexGrow={1}
					direction="column"
					align="center"
					borderColor={mempoolBorderColor}
					borderLeftWidth={1}
					borderTopWidth={1}
					transition={STYLE_TRANSITION_ALL}
					pt={responsivePt}
				>
					<Label>
						<FormattedMessage id="mempool.unconfirmed" />
					</Label>
					
					<UnconfirmedTxs />

					<Label
						color={labelColor}
						fontWeight="semibold"
					>
						<FormattedMessage id="mempool.transactions" />
					</Label>
				</Flex>

				<Flex
					direction="column"
					align="center"
					alignSelf="stretch"
					w={sideW}
					borderColor={mempoolBorderColor}
					borderLeftWidth={1}
					borderTopWidth={1}
					transition={STYLE_TRANSITION_ALL}
					pt={responsivePt}
				>
					<Flex direction="column" align="center">
						<Label>
							<FormattedMessage
								id="mempool.depth"
							/>
						</Label>
						<Flex
							transform="translateX(-3px)"
							my={1}
						>
							<Depth />
						</Flex>
						<Label
							color={labelColor}
							fontWeight="semibold"
						>
							<FormattedMessage
								id="mempool.blocks"
							/>
						</Label>
					</Flex>
				</Flex>

				<Flex
					direction="column"
					pos="absolute"
					w="100%"
					h={height}
					flexShrink={0}
					top={0}
					left={0}
					right={0}
					transition={STYLE_TRANSITION_ALL}
				>
					<Flex
						pos="relative"
						h={height}
						px={5}
						top="-8px"
						left={0}
						right={0}
						m="auto"
						justify="center"
						align="center"
						bg={bg}
						borderRadius="30px"
						borderWidth={1}
						borderColor={mempoolLabelBorderColor}
						transition={STYLE_TRANSITION_ALL}
					>
						<Text
							fontSize={responsiveSectionLabelSize}
							lineHeight="14px"
							fontWeight="bold"
							color={mempoolLabelColor}
							textTransform="uppercase"
							transition={STYLE_TRANSITION_ALL}
						>
							<FormattedMessage id="mempool.section_title" />
						</Text>
					</Flex>
				</Flex>
			</Flex>

			<Flex
				align="center"
				justify="center"
				gap={1}
				color={labelColor}
				fontWeight="semibold"
				fontSize={responsiveMempoolUsageFontSize}
			>
				<Usage />

				<Text>{'/'}</Text>

				<Text>{maxMempool}</Text>
			</Flex>
		</Flex>
	)
}
