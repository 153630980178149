import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import type { BitcoinDateAlert } from '../../../constants'

interface CalAlertState {
	alert: BitcoinDateAlert | null
	dismissed: boolean
}

const initialState = {
	alert: null,
	dismissed: false,
} as CalAlertState

export const calAlertSlice = createSlice({
	name: 'calAlert',
	initialState,
	reducers: {
		setCalAlert: (state, action: PayloadAction<BitcoinDateAlert | null>) => {
			state.alert = action.payload
		},
		setCalAlertDismissed: (state, action: PayloadAction<boolean>) => {
			state.dismissed = action.payload
		},
	},
})

export const {
	setCalAlert,
	setCalAlertDismissed,
} = calAlertSlice.actions

export default calAlertSlice.reducer