import { getAverageOfNumbers, extractValuesByKey } from './'

const powerTable = {
	0: 'H/s',
	3: 'kH/s',
	6: 'MH/s',
	9: 'GH/s',
	12: 'TH/s',
	15: 'PH/s',
	18: 'EH/s',
}
const selectedPower = 18
const hashrateDivider = Math.pow(10, selectedPower)
const hashrateUnit = powerTable[selectedPower]

export const getFormattedHashrate = (hashrate: number) => {
	const shortHashrate = (hashrate / hashrateDivider).toFixed(1)
	return `${shortHashrate} ${hashrateUnit}`
}

const getDaysHashrateValues = (
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	arr: any[],
	days: number,
	currentHashrate?: number | undefined,
) => {
	const adjustedDays = currentHashrate ? days - 1 : days
	const selectedValues = arr.slice(0, adjustedDays)
	const selectedAvgHashrates = extractValuesByKey(selectedValues, 'avgHashrate')
	const mergedArray = currentHashrate
		? [currentHashrate, ...selectedAvgHashrates]
		: [...selectedAvgHashrates]

	return mergedArray
}

export const getHashrateAverages = (
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	hashrates: any[],
	currentHashrate: number,
) => {
	const reversedHashrates = hashrates.reverse()
	const sevenDayAverage = getAverageOfNumbers(getDaysHashrateValues(reversedHashrates, 7, currentHashrate))
	const yesterdaySevenDayAverage = getAverageOfNumbers(getDaysHashrateValues(reversedHashrates, 7))
	const twoWeekAverage = getAverageOfNumbers(getDaysHashrateValues(reversedHashrates, 14, currentHashrate))
	const yesterdayTwoWeekAverage = getAverageOfNumbers(getDaysHashrateValues(reversedHashrates, 14))
	const monthAverage = getAverageOfNumbers(getDaysHashrateValues(reversedHashrates, 30, currentHashrate))
	const yesterdayMonthAverage = getAverageOfNumbers(getDaysHashrateValues(reversedHashrates, 30))
	const threeMonthAverage = getAverageOfNumbers(getDaysHashrateValues(reversedHashrates, 90, currentHashrate))
	const yesterdayThreeMonthAverage = getAverageOfNumbers(getDaysHashrateValues(reversedHashrates, 90))

	return {
		sevenDayAverage,
		yesterdaySevenDayAverage,
		twoWeekAverage,
		yesterdayTwoWeekAverage,
		monthAverage,
		yesterdayMonthAverage,
		threeMonthAverage,
		yesterdayThreeMonthAverage,
	}
}
