import { useState, useEffect } from 'react'
import ReactPlayer from 'react-player'
import {
	Flex,
	Box,
	Text,
	Button,
	Modal,
	ModalContent,
	ModalFooter,
	ModalBody,
	Image,
	useColorModeValue,
} from '@chakra-ui/react'

import { BITCOIN_ORANGE } from '../../../constants'
import { ModalOverlay, CloseButtonLabel } from '../../shared'

interface HalvingVideoProps {
	isOpen: boolean
	onClose: () => void
}
type Progress = {
	loaded: number
	loadedSeconds: number
	played: number
	playedSeconds: number
}

const PauseIcon = () => {
	return (
		<Flex gap="5px">
			<Box
				h="20px"
				w="5px"
				bg="currentColor"
				borderRadius="1px"
			/>

			<Box
				h="20px"
				w="5px"
				bg="currentColor"
				borderRadius="1px"
			/>
		</Flex>
	)
}

const PlayIcon = () => {
	return (
		<Flex>
			<Box
				w={0}
				h={0}
				borderTopWidth="10px"
				borderTopColor="transparent"
				borderBottomWidth="10px"
				borderBottomColor="transparent"
				borderLeftWidth="16px"
				borderLeftColor="currentColor"
			/>
		</Flex>
	)
}

export const HalvingVideo = ({ isOpen, onClose }: HalvingVideoProps) => {
	const [progress, setProgress] = useState<Progress>()
	const [progressPlayed, setProgressPlayed] = useState(0)
	const [progressSeconds, setProgressSeconds] = useState(0)
	const [playing, setPlaying] = useState(true)
	const [ready, setReady] = useState(false)

	const progressSecondsString = `0:${progressSeconds < 10 ? '0' : ''}${progressSeconds} / 1:00`

	const bg = useColorModeValue('white', 'black')
	const color = useColorModeValue('black', 'white')
	const colorAlt = useColorModeValue('rgba(0,0,0,0.7)', 'rgba(255,255,255,0.7)')
	const colorAlt2 = useColorModeValue('rgba(0,0,0,0.1)', 'rgba(255,255,255,0.3)')
	const bgGradient = useColorModeValue(`linear(to-r, black, black, ${BITCOIN_ORANGE})`, `linear(to-r, white, white, ${BITCOIN_ORANGE})`)
	const interactionStyles = {
		borderColor: BITCOIN_ORANGE,
		color: BITCOIN_ORANGE,
	}

	const handlePlayVideo = () => {
		setPlaying(false)
		setReady(true)
		setPlaying(true)
	}
	const handlePlayPause = () => playing ? setPlaying(false) : setPlaying(true)
	const handleClose = () => {
		setProgressPlayed(0)
		onClose()
	}

	useEffect(() => {
		const progressPercent = (progress?.played || 0) * 100
		const progressSeconds = Math.floor(progress?.playedSeconds || 0)

		setProgressPlayed(progressPercent > 100 ? 100 : progressPercent)
		setProgressSeconds(progressSeconds)
	}, [progress])

	return (
		<Modal
			key="halvingVideo"
			isOpen={isOpen}
			onClose={onClose}
			isCentered
			motionPreset="slideInBottom"
			size="full"
		>
			<ModalOverlay />

			<ModalContent
				bg={bg}
				color={color}
				pos="relative"
				borderWidth={0}
				borderRadius="none"
				dir="direction"
				width="100%"
			>
				<ModalBody
					display="flex"
					flexDirection="column"
					justifyContent="center"
					width="100%"
				>
					{!ready && (
						<Image
							src="video/halving-code.jpg"
							pos="absolute"
							boxSize="100%"
							objectFit="contain"
						/>
					)}
					
					<ReactPlayer
						className="halving-video-player"
						url="video/halving720.mp4"
						playing={playing}
						onReady={handlePlayVideo}
						onProgress={(x) => setProgress(x)}
						onEnded={handleClose}
						width="100%"
						height="100%"
						config={{
							file: {
								attributes: { preload: 'auto' },
							},
						}}
					/>
				</ModalBody>

				<ModalFooter flexDirection="column" pt={0}>
					<Text
						color={colorAlt}
						fontSize="sm"
						fontWeight="bold"
						letterSpacing="1px"
					>
						{progressSecondsString}
					</Text>

					<Flex
						w="100%"
						justify="space-between"
						align="center"
						gap={8}
					>
						<Flex
							w="100%"
							h="5px"
							borderRadius="10px"
							bg={colorAlt2}
						>
							<Box
								w={`${progressPlayed}%`}
								h="5px"
								borderRadius="10px"
								bgGradient={bgGradient}
								transition="all 1s linear"
							/>
						</Flex>

						<Flex gap={4}>
							<Button
								onClick={handlePlayPause}
								variant="outline"
								colorScheme="black"
								fontSize="sm"
								textTransform="uppercase"
								borderWidth={2}
								_active={interactionStyles}
								_hover={interactionStyles}
							>
								<Flex
									justify="center"
									align="center"
									w="40px"
									h="40px"
								>
									{playing ? <PauseIcon /> : <PlayIcon />}
								</Flex>
								
							</Button>

							<Button
								onClick={handleClose}
								variant="outline"
								colorScheme="black"
								fontSize="sm"
								textTransform="uppercase"
								borderWidth={2}
								_active={interactionStyles}
								_hover={interactionStyles}
							>
								<CloseButtonLabel />
							</Button>
						</Flex>
					</Flex>

					<Flex justify="center">
						<Text fontSize="xs" color={colorAlt}>
							Animation by <b>ANATOMY OF BITCOIN</b>, Music & Video Editing by <b>Reel Richard</b>
						</Text>
					</Flex>
				</ModalFooter>
			</ModalContent>
		</Modal>
	)
}
