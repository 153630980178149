import { atom } from 'recoil'

export const nodesState = atom({
	key: 'nodes',
	default: 0,
})

export const lightningLiquidityState = atom({
	key: 'lightningLiquidity',
	default: 0,
})
