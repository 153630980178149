import {
	Flex,
	Text,
	useColorModeValue,
} from '@chakra-ui/react'
import { ExternalLinkIcon } from '@chakra-ui/icons'
import add from 'date-fns/add'
import format from 'date-fns/format'
import { FormattedMessage, useIntl } from 'react-intl'
import { useRecoilValue } from 'recoil'

import {
	blockState,
	circlesBreakpointState,
} from '../../../../state'
import { useAppSelector } from '../../../../hooks'
import { dateLocales } from '../../../../lang/dateFnsLocales'
import { Label } from '../../../shared'
import {
	getBlocksToHalving,
	getBreakpointValue,
	getNextHalvingBlock,
} from '../../../../utils'
import {
	BITCOIN_ORANGE,
	// EPOCH_BLOCKS,
} from '../../../../constants'
import { primaryDataFontSize } from './dataComponents.constants'

interface DataNextHalvingProps {
	// eslint-disable-next-line
	onSearchFutureBlock: (x: number) => void
}

export const DataNextHalving = ({ onSearchFutureBlock }: DataNextHalvingProps) => {
	const intl = useIntl()
	const block = useRecoilValue(blockState)
	const circlesBreakpoint = useRecoilValue(circlesBreakpointState)
	const fontSize = getBreakpointValue(primaryDataFontSize, circlesBreakpoint)
	const { userLocale } = useAppSelector(({ settings }) => settings)
	const blocksToHalving = getBlocksToHalving(block.height)
	const nextHalvingBlock = getNextHalvingBlock(block.height)
	const estHalvingDate = add(new Date(), { minutes: 10 * blocksToHalving })
	const estHalvingDateString = format(estHalvingDate, 'LLL d, yyy', { locale: dateLocales[userLocale] })
	// const progressPercentage = (((EPOCH_BLOCKS - blocksToHalving) / EPOCH_BLOCKS) * 100).toFixed(2)
	const wizardFontSize = getBreakpointValue({ base: '12px', sm: '14px' }, circlesBreakpoint)
	const textColor = useColorModeValue('black', 'white')
	const textColorAlt = useColorModeValue('rgba(0,0,0,0.7)', 'rgba(255,255,255,0.7)')

	const transitionBlocks = 12000
	const halvingBlocks = getBlocksToHalving(block.height)
	const shouldAnimate = halvingBlocks <= transitionBlocks

	const color = shouldAnimate
		? BITCOIN_ORANGE
		: textColor

	return (
		<Flex
			direction="column"
			align="center"
		>
			<Label mb={1}>
				<FormattedMessage id="data.next_halving.title" />
			</Label>

			<Text
				color={color}
				fontSize={fontSize}
				fontWeight="bold"
				lineHeight="none"
				whiteSpace="nowrap"
				display="flex"
				align="center"
				gap="3px"
				mb={1}
			>
				{blocksToHalving}
			</Text>

			<Label color={textColorAlt} fontWeight="semibold" mb={2}>
				{intl.formatMessage({ id: 'data.next_halving.blocks.lower_case' })} {intl.formatMessage({ id: 'data.next_halving.to_go.lower_case' })}
			</Label>

			{/* <Label color="white" fontWeight="bold">
				<span style={{ fontWeight: 'normal' }}>{intl.formatMessage({ id: 'data.next_halving.progress' })}:</span> {progressPercentage}%
			</Label> */}

			<Label color={textColorAlt} fontWeight="bold" mt={2}>
				{intl.formatMessage({ id: 'shared.estimated.abbr' })} {estHalvingDateString}
			</Label>

			<Text
				color={color}
				onClick={() => onSearchFutureBlock(nextHalvingBlock)}
				display="flex"
				alignItems="center"
				gap={1}
				fontSize={wizardFontSize}
				fontWeight="bold"
				textTransform="uppercase"
				whiteSpace="nowrap"
				mt={1}
				cursor="pointer"
				_hover={{
					color: BITCOIN_ORANGE,
				}}
			>
				🔮 {intl.formatMessage({ id: 'data.next_halving.wizard' })} <ExternalLinkIcon />
			</Text>
		</Flex>
	)
}
