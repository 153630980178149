import { Flex, Box } from '@chakra-ui/react'
import { useRecoilValue } from 'recoil'

import { circlesBreakpointState } from '../../../state'
import { getBreakpointValue } from '../../../utils'

export interface CircleIndicatorArrowProps {
	color: string
}

export const CircleIndicatorArrow = ({ color }: CircleIndicatorArrowProps) => {
	const circlesBreakpoint = useRecoilValue(circlesBreakpointState)

	const responsiveTop = getBreakpointValue({ base: '-14px', md: '-17px', lg: '-25px', jumbo: '-30px' }, circlesBreakpoint)
	const responsiveCircleSize = getBreakpointValue({ base: '14px', md: '20px', jumbo: '24px' }, circlesBreakpoint)
	const responsiveCircleTop = getBreakpointValue({ base: '10px', md: '8px', lg: '16px' }, circlesBreakpoint)
	const borderSides = getBreakpointValue({ base: '10px solid transparent', md: '12px solid transparent' }, circlesBreakpoint)
	const borderBottom = getBreakpointValue({ base: `10px solid ${color}`, md: `20px solid ${color}` }, circlesBreakpoint)

	return (
		<Flex
			direction="column"
			align="center"
			pos="absolute"
			top={responsiveTop}
			left={0}
			w="20px"
		>
			<Box
				pos="absolute"
				borderLeft={borderSides}
				borderRight={borderSides}
				borderBottom={borderBottom}
			/>
			<Box
				pos="absolute"
				bg={color}
				borderRadius="50%"
				w={responsiveCircleSize}
				h={responsiveCircleSize}
				top={responsiveCircleTop}
			/>
		</Flex>
	)
}
