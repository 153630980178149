interface MempoolSpaceLogoProps {
	width?: number
}

export const MempoolSpaceLogo = ({ width = 500 }: MempoolSpaceLogoProps) => {
	return (
		<svg width={width} fill="none" viewBox="0 0 500 126">
			<path d="M163.658 113.263C161.089 113.263 158.992 111.146 158.992 108.535C158.992 105.966 161.048 103.951 163.658 103.951C166.269 103.951 168.325 105.966 168.325 108.535C168.325 111.125 166.228 113.263 163.658 113.263Z" fill="#9857FF" />
			<path d="M189.767 113.263C183.867 113.263 177.803 111.392 174.637 108.617L174 108.062L177.104 102.594L178.05 103.334C180.434 105.205 185.08 107.096 190.054 107.096C195.79 107.096 198.565 105.554 198.565 102.368C198.565 99.3461 195.194 98.5856 189.664 97.6194C183.312 96.5094 175.398 95.1115 175.398 86.6012C175.398 79.8999 181.359 75.5625 190.589 75.5625C195.584 75.5625 200.785 76.9192 203.807 79.0365L204.567 79.571L201.484 85.2034L200.559 84.5867C197.804 82.7572 194.269 81.7499 190.609 81.7499C185.368 81.7499 182.366 83.4767 182.366 86.4779C182.366 89.7052 185.84 90.548 191.576 91.5759C197.784 92.6859 205.513 94.0632 205.513 102.224C205.513 105.575 204.012 108.37 201.175 110.323C198.421 112.255 194.474 113.263 189.767 113.263Z" fill="#9857FF" />
			<path d="M212.316 125.863V75.9733H218.997V80.8451C222.122 77.4945 226.541 75.7061 231.722 75.7061C236.984 75.7061 241.774 77.5356 245.186 80.8862C248.66 84.278 250.572 89.0882 250.572 94.4123C250.572 99.7569 248.66 104.588 245.206 108C241.774 111.392 237.005 113.242 231.742 113.242C226.624 113.242 222.327 111.536 219.162 108.247V125.843H212.316V125.863ZM231.434 81.8729C228.001 81.8729 224.876 83.1063 222.656 85.3264C220.354 87.6287 219.141 90.7738 219.141 94.4123C219.141 101.792 224.198 106.952 231.454 106.952C238.69 106.952 243.768 101.792 243.768 94.4123C243.768 90.7738 242.555 87.6287 240.252 85.3264C237.991 83.1063 234.867 81.8729 231.434 81.8729Z" fill="#9857FF" />
			<path d="M269.194 113.262C260.643 113.262 255.113 108.884 255.113 102.1C255.113 98.8936 256.285 96.2212 258.526 94.3712C261.054 92.2744 264.878 91.2055 269.893 91.2055H280.233V90.1777C280.233 84.7714 277.17 82.0168 271.147 82.0168C266.995 82.0168 262.781 83.4558 259.882 85.8814L258.916 86.7037L255.956 81.1329L256.593 80.5985C260.417 77.4328 265.823 75.7061 271.846 75.7061C281.734 75.7061 287.181 80.8862 287.181 90.301V112.975H280.5V109.398C277.89 111.906 273.984 113.262 269.194 113.262ZM270.181 96.6735C262.925 96.6735 262.082 99.613 262.082 101.813C262.082 105.348 264.98 107.363 270.037 107.363H270.058C270.181 107.363 270.304 107.363 270.448 107.363C275.115 107.363 278.547 105.225 280.377 101.175V96.6735H270.181V96.6735Z" fill="#9857FF" />
			<path d="M313.658 112.995C302.742 112.995 294.828 105.184 294.828 94.4123C294.828 89.1088 296.781 84.3191 300.337 80.9274C303.852 77.5561 308.786 75.7061 314.233 75.7061C320.77 75.7061 326.053 78.2961 329.137 83.0241L329.712 83.8875L324.347 87.3204L323.792 86.4776C321.777 83.4558 318.303 81.729 314.254 81.729C310.656 81.729 307.552 82.9213 305.291 85.1825C303.01 87.4643 301.797 90.6094 301.797 94.2684C301.797 97.9685 303.01 101.134 305.332 103.395C307.573 105.595 310.739 106.808 314.233 106.808C318.303 106.808 321.695 105.163 323.771 102.183L324.326 101.381L329.671 104.793L329.137 105.657C326.238 110.323 320.811 112.995 314.254 112.995C314.048 112.995 313.863 112.995 313.658 112.995Z" fill="#9857FF" />
			<path d="M352.118 113.386C346.506 113.386 341.429 111.516 337.852 108.103C334.255 104.691 332.281 99.881 332.281 94.5363C332.281 83.7031 340.031 75.8301 350.7 75.8301C361.204 75.8301 368.83 83.5387 368.995 94.228C368.995 94.8447 368.995 96.9414 368.995 96.9414H339.373C340.36 103.047 345.396 107.076 352.241 107.076C356.414 107.076 360.032 105.596 362.417 102.903L363.177 102.039L367.206 106.603L366.61 107.281C363.218 111.228 358.059 113.386 352.118 113.386ZM362.335 91.3295C361.368 85.6354 356.805 81.7297 350.864 81.7297C344.964 81.7297 340.483 85.5327 339.414 91.3295H362.335Z" fill="#9857FF" />
			<path d="M219.548 56.1979V33.8327C219.548 30.3587 218.746 27.6863 217.163 25.9185C215.601 24.1507 213.237 23.2667 210.195 23.2667C206.762 23.2667 203.946 24.3768 202.013 26.453C200.081 28.5497 199.053 31.6331 199.053 35.395V56.2185H191.447V33.8327C191.447 26.823 188.282 23.2667 182.032 23.2667C178.6 23.2667 175.783 24.3768 173.851 26.453C171.919 28.5497 170.891 31.6331 170.891 35.395V56.2185H163.285V16.4215H170.685V20.7589C173.687 17.7988 178.003 16.1748 182.999 16.1748C188.96 16.1748 193.667 18.4977 196.36 22.7528C199.608 18.5593 204.85 16.1748 210.955 16.1748C215.93 16.1748 219.877 17.6137 222.693 20.43C225.632 23.3901 227.174 27.8713 227.154 33.3599V56.1979H219.548V56.1979Z" fill="white" />
			<path d="M255.397 56.5679C243.145 56.5679 234.594 48.2426 234.594 36.32C234.594 24.644 242.775 16.1748 254.04 16.1748C265.387 16.1748 273.342 24.459 273.383 36.32C273.383 36.4433 273.363 36.5872 273.363 36.7106C273.342 36.8339 273.342 36.9367 273.342 37.0395V37.1011L273.322 37.1628C273.281 37.3684 273.281 37.5945 273.281 37.8617V39.1568H242.343C243.412 45.4881 248.49 49.5377 255.5 49.5377C259.796 49.5377 263.393 47.996 265.901 45.0975L266.888 44.008L271.41 49.1677L270.732 50.0516C267.196 54.2451 261.749 56.5679 255.397 56.5679ZM265.798 32.846C265.284 30.0503 263.989 27.6658 262.036 25.9185C259.919 24.0273 257.144 23.0406 254.061 23.0406C250.998 23.0406 248.264 24.0273 246.146 25.9185C244.194 27.6658 242.919 30.0503 242.405 32.846H265.798Z" fill="white" />
			<path d="M337.274 56.198V33.8327C337.274 30.3587 336.473 27.6864 334.89 25.9185C333.328 24.1507 330.964 23.2668 327.921 23.2668C324.488 23.2668 321.672 24.3768 319.74 26.453C317.808 28.5497 316.78 31.6332 316.78 35.395V56.2185H309.174V33.8327C309.174 26.823 306.008 23.2668 299.759 23.2668C296.326 23.2668 293.51 24.3768 291.578 26.453C289.645 28.5497 288.618 31.6332 288.618 35.395V56.2185H281.012V16.4215H288.412V20.7589C291.413 17.7782 295.73 16.1543 300.725 16.1543C306.687 16.1543 311.394 18.4772 314.087 22.7323C317.335 18.5388 322.577 16.1543 328.682 16.1543C333.656 16.1543 337.603 17.5932 340.419 20.4095C343.359 23.3696 344.901 27.8508 344.88 33.3394V56.1774H337.274V56.198Z" fill="white" />
			<path d="M354.297 69.9296V16.4215H361.697V21.3139C364.986 17.9427 369.467 16.1543 374.75 16.1543C386.241 16.1543 394.567 24.6235 394.567 36.2995C394.567 48.0371 386.241 56.5474 374.75 56.5474C369.57 56.5474 365.171 54.8413 361.923 51.5728V69.909H354.297V69.9296ZM374.422 23.1023C367.021 23.1023 361.841 28.5292 361.841 36.32C361.841 44.1109 367.021 49.5377 374.422 49.5377C381.822 49.5377 387.002 44.1109 387.002 36.32C387.002 28.6525 381.719 23.1023 374.422 23.1023Z" fill="white" />
			<path d="M420.321 56.5679C408.604 56.5679 400.094 48.0576 400.094 36.32C400.094 24.644 408.604 16.1748 420.321 16.1748C425.995 16.1748 431.134 18.2099 434.813 21.91C438.472 25.5896 440.466 30.7081 440.446 36.3405C440.446 48.0576 431.977 56.5679 420.321 56.5679ZM420.321 23.1023C412.921 23.1023 407.741 28.5291 407.741 36.32C407.741 44.1108 412.921 49.5377 420.321 49.5377C427.66 49.5377 432.799 44.1108 432.799 36.32C432.778 28.5291 427.66 23.1023 420.321 23.1023Z" fill="white" />
			<path d="M464.598 56.5679C452.881 56.5679 444.371 48.0576 444.371 36.32C444.371 24.644 452.881 16.1748 464.598 16.1748C476.254 16.1748 484.723 24.644 484.723 36.32C484.723 48.0576 476.254 56.5679 464.598 56.5679ZM464.598 23.1023C457.198 23.1023 452.018 28.5291 452.018 36.32C452.018 44.1108 457.198 49.5377 464.598 49.5377C471.937 49.5377 477.076 44.1108 477.076 36.32C477.076 28.5291 471.958 23.1023 464.598 23.1023Z" fill="white"></path><path d="M499.996 1.14844H492.391V56.1982H499.996V1.14844Z" fill="white" />
			<path d="M124.706 110.25C124.706 118.849 117.772 125.791 109.183 125.791H15.5236C6.93387 125.791 0 118.849 0 110.25V16.4837C0 7.88416 6.98561 0.942383 15.5236 0.942383H109.183C117.772 0.942383 124.706 7.88416 124.706 16.4837V110.25Z" fill="#2E3349" />
			<path d="M0 63.5225V110.25C0 118.849 6.98561 125.791 15.5753 125.791H109.183C117.772 125.791 124.758 118.849 124.758 110.25V63.5225H0Z" fill="url(#paint0_linear986)" />
			<path opacity="0.3" d="M109.909 109.11C109.909 111.026 108.615 112.581 107.011 112.581H90.8665C89.2624 112.581 87.9688 111.026 87.9688 109.11V17.6232C87.9688 15.7065 89.2624 14.1523 90.8665 14.1523H107.011C108.615 14.1523 109.909 15.7065 109.909 17.6232V109.11Z" fill="white" />
			<defs>
				<linearGradient x1="62.3768" y1="36.3949" x2="62.3768" y2="156.837" gradientUnits="userSpaceOnUse" id="paint0_linear986">
					<stop stopColor="#AE61FF"></stop>
					<stop offset="1" stopColor="#13EFD8"></stop>
				</linearGradient>
			</defs>
		</svg>
	)
}
