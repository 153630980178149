export const buttonBreaks = {
	base: '13%',
	sm: '11%',
}

export const buttonIconSize = {
	base: '19px',
	xxs: '22px',
	xs: '24px',
	md: '30px',
	lg: '36px',
	xl: '38px',
	xxl: '42px',
	jumbo: '50px',
}

export const buttonBottom = {
	base: '13%',
	xs: '11.5%',
	sm: '10%',
}
