export const abbreviateNumber = (n: number, decimals = 2) => {
	if (n < 1e3) return n
	if (n >= 1e3 && n < 1e6) return `${(n / 1e3).toFixed(decimals)}K`
	if (n >= 1e6 && n < 1e9) return `${(n / 1e6).toFixed(decimals)}M`
	if (n >= 1e9 && n < 1e12) return `${(n / 1e9).toFixed(decimals)}B`
	if (n >= 1e12) return `${(n / 1e12).toFixed(decimals)}T`
}

// always round down supply
export const abbreviateSupply = (n: number, decimals = 2) => {
	if (n < 1e3) return n
	if (n >= 1e3 && n < 1e6) return `${roundDown((n / 1e3), decimals)}K`
	if (n >= 1e6 && n < 1e9) return `${roundDown((n / 1e6), decimals)}M`
	if (n >= 1e9 && n < 1e12) return `${roundDown((n / 1e9), decimals)}B`
	if (n >= 1e12) return `${roundDown((n / 1e12), decimals)}T`
}

export const abbreviateNumberHash = (n: number) => {
	if (n < 1e3) return n
	if (n >= 1e3 && n < 1e6) return `${(n / 1e3).toFixed(2)}K`
	if (n >= 1e6 && n < 1e9) return `${(n / 1e6).toFixed(2)}M`
	if (n >= 1e9 && n < 1e12) return `${(n / 1e9).toFixed(2)}G`
	if (n >= 1e12) return `${(n / 1e12).toFixed(2)}T`
}

export const abbreviateNumberBlockSize = (n: number) => {
	if (n < 1e3) return `${n.toFixed(0)} bytes`
	if (n >= 1e3 && n < 1e6) return `${(n / 1e3).toFixed(2)} KB`
	if (n >= 1e6 && n < 1e9) return `${(n / 1e6).toFixed(2)} MB`
	if (n >= 1e9 && n < 1e12) return `${(n / 1e9).toFixed(2)} GB`
}

export const abbreviateMempoolSize = (n: number) => {
	if (n < 1e3) return `${n} bytes`
	if (n >= 1e3 && n < 1e6) return `${(n / 1e3).toFixed(0)} KB`
	if (n >= 1e6 && n < 1e9) return `${(n / 1e6).toFixed(0)} MB`
	if (n >= 1e9 && n < 1e12) return `${(n / 1e9).toFixed(2)} GB`
}

export const abbreviateDiskSize = (n: number) => {
	if (n < 1e3) return `${n} bytes`
	if (n >= 1e3 && n < 1e6) return `${(n / 1e3).toFixed(0)} KB`
	if (n >= 1e6 && n < 1e9) return `${(n / 1e6).toFixed(0)} MB`
	if (n >= 1e9 && n < 1e12) return `${(n / 1e9).toFixed(2)} GB`
	if (n >= 1e12) return `${(n / 1e12).toFixed(2)}TB`
}

export const abbreviateMoscowTime = (n: number) => {
	if (n < 1e6) return n
	if (n >= 1e6 && n < 1e9) return `${(n / 1e6).toFixed(2)}M`
	if (n >= 1e9 && n < 1e12) return `${(n / 1e9).toFixed(2)}B`
	if (n >= 1e12) return `${(n / 1e12).toFixed(2)}T`
}

export const roundDown = (n: number, decimals = 2) => {
	const factor = Math.pow(10, decimals)
	return Math.floor(n * factor) / factor
}

export const roundToEven = (n: number) => {
	return 2 * Math.round(n / 2)
}

export const positiveInt = (n: number) => {
	return Math.abs(n)
}

export const getSumOfNumbers = (numbers: number[]) => {
	const sum = numbers.reduce((partialSum, a) => partialSum + a, 0)
	return sum
}

export const getAverageOfNumbers = (numbers: number[]) => {
	const numbersSum = getSumOfNumbers(numbers)
	return numbersSum / numbers.length
}

export const decimalPlaces = (n: number, decimals = 2) => {
	return (Math.round(n * 100) / 100).toFixed(decimals)
}

export const numbersOnly = (inputValue: number | string) => {
	const strValue = String(inputValue)
	return Number(strValue.replace(/\D/g, ''))
}

export const toFixedDecimals = (n: number, maxDecimals: number) => {
	if (isNaN(n) || isNaN(maxDecimals) || maxDecimals < 0) {
		throw new Error('Invalid input: n and maxDecimals must be a number, and maxDecimals must be non-negative.')
	}

	const multiplier = Math.pow(10, maxDecimals)
	const result = Math.round(n * multiplier) / multiplier
	const actualDecimalPlaces = (result.toString().split('.')[1] || '').length

	if (actualDecimalPlaces > maxDecimals) {
		return result.toFixed(maxDecimals)
	}

	return result
}

export const getNumberSplitByDecimal = (n: number) => {
	return (
		[
			(n > 0)
				? Math.floor(n)
				: Math.ceil(n),
			(n % 1).toFixed(4)
		]
	)
}

export const calculateDecimalPlaces = (value: number) => {
	const stringValue = value.toString()
	const decimalIndex = stringValue.indexOf('.')
	return decimalIndex === -1 ? 0 : stringValue.length - decimalIndex - 1
}

export const percentStringToNumber = (percentString: string) => {
	if (percentString.includes('%')) {
		const numStr = percentString.split('%')[0]
		const num = Number(numStr.trim())
		
		return isNaN(num) ? 0 : num
	}
}
