export type SelectedAudioSound = 'DROP' | 'WHOOSH' | 'GONG' | 'DOORBELL' | 'BELL'
export const AUDIO_SOUNDS = {
	drop: 'DROP',
	whoosh: 'WHOOSH',
	gong: 'GONG',
	doorbell: 'DOORBELL',
	bell: 'BELL',
}

export type SelectedAlarmSound = 'ALARM_CLOCK' | 'ALARM_GONG' | 'ALARM_GRANDFATHER_CLOCK' | 'ALARM_FRANKIE'
export const ALARM_SOUNDS = {
	alarmClock: 'ALARM_CLOCK',
	alarmGong: 'ALARM_GONG',
	alarmGrandfatherClock: 'ALARM_GRANDFATHER_CLOCK',
	alarmFrankie: 'ALARM_FRANKIE',
}
