import { useEffect, useLayoutEffect, useState, useRef } from 'react'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { Box, Text } from '@chakra-ui/react'
import { gsap } from 'gsap'

import { useAppSelector } from '../../../../hooks'
import { blockState, scrubBlockState, showBlockDropState } from '../../../../state'
import { BITCOIN_ORANGE } from '../../../../constants'

export const BlockHeightDrop = () => {
	const { reducedMotion } = useAppSelector(({ settings }) => settings)
	const [refAcquired, setRefAcquired] = useState(false)
	const [animationComplete, setAnimationComplete] = useState(false)
	const block = useRecoilValue(blockState)
	const scrubBlock = useRecoilValue(scrubBlockState)
	const setShowBlockDrop = useSetRecoilState(showBlockDropState)
	const blockDropRef = useRef<HTMLDivElement>(null)

	const blockHeight = scrubBlock.height < block.height
		? scrubBlock.height
		: block.height

	useEffect(() => {
		setRefAcquired(true)
	}, [])

	useLayoutEffect(() => {
		const ctx = gsap.context(() => {
			if (refAcquired && blockDropRef.current && !animationComplete) {
				gsap.to(blockDropRef.current, {
					opacity: 1,
					scale: 0.015,
					duration: 0.35,
					color: 'black',
					ease: 'easeIn',
					onComplete: () => {
						setAnimationComplete(true)
						setTimeout(() => setShowBlockDrop(false), 500)
					},
				})
			}
		}, blockDropRef)

		return () => ctx.revert()
	}, [
		refAcquired,
		animationComplete,
		setShowBlockDrop,
	])

	return (
		<Box
			ref={blockDropRef}
			display="flex"
			justifyContent="center"
			alignItems="center"
			opacity={0}
			position="absolute"
			zIndex="1111"
			top={0}
			left={0}
			right={0}
			bottom={0}
			transform={reducedMotion ? 'scale(2.5)' : 'scale(7.5)'}
			transformOrigin="center"
			color={BITCOIN_ORANGE}
		>
			<Text
				fontSize={{ base: '53px', sm: '69px', md: '86px' }}
				fontWeight="bold"
				lineHeight="none"
				mb={{ base: '3%', md: 0 }}
				mt={{ base: '3%', md: 0 }}
			>
				{blockHeight}
			</Text>
		</Box>
	)
}
