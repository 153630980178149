import { Box, type BoxProps } from '@chakra-ui/react'
import { useRecoilValue } from 'recoil'

import { circlesBreakpointState } from '../../../state'
import { getBreakpointValue } from '../../../utils'

interface TopMarkProps {
	staticMode: boolean
	color: string
	disableBlur?: boolean
}

type Props = BoxProps & TopMarkProps

export const TopMark = ({
	staticMode,
	color,
	disableBlur = false,
	...rest
}: Props) => {
	const circlesBreakpoint = useRecoilValue(circlesBreakpointState)

	const responsiveHeight = getBreakpointValue({
		base: '20px',
		md: '24px',
		lg: '34px',
		jumbo: '40px',
	}, circlesBreakpoint)
	const responsiveMarkTop = getBreakpointValue({
		base: '-12px',
		md: '-14px',
		lg: '-21px',
		jumbo: '-25px',
	}, circlesBreakpoint)
	const responsiveWidth = getBreakpointValue({
		base: '3px',
		lg: '6px',
		jumbo: '8px',
	}, circlesBreakpoint)

	const opacity = staticMode && !disableBlur ? 0.3 : 0.9
	const filter = staticMode && !disableBlur ? 'blur(4px)' : 'blur(0)'

	return (
		<Box
			pos="absolute"
			h={responsiveHeight}
			w={responsiveWidth}
			top={responsiveMarkTop}
			left={0}
			right={0}
			m="auto"
			opacity={opacity}
			filter={filter}
			bg={color}
			{...rest}
		/>
	)
}
