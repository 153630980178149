import { useEffect, useState, useRef } from 'react'
import Slider from 'react-slick'
import { Flex, Image } from '@chakra-ui/react'
import { useRecoilValue } from 'recoil'

import { useAppSelector } from '../../../hooks'
import { circlesWrapWidthState } from '../../../state'
import type { RootState } from '../../../store'

interface SlideshowProps {
	leftSide: boolean
}

export const SponsorsSlideshow = ({ leftSide }: SlideshowProps) => {
	const circleWrapWidth = useRecoilValue(circlesWrapWidthState)
	const [slideshowHeight, setSlideshowHeight] = useState(0)
	const [maxWidth, setMaxWidth] = useState(0)
	const { sponsors, edgeToEdge, slideshowTiming } = useAppSelector((state: RootState) => state.confModeSponsors)
	const slideshowRef = useRef<HTMLDivElement>(null)
	const speed = slideshowTiming ? slideshowTiming * 1000 : 10000

	useEffect(() => {
		if (slideshowRef.current && circleWrapWidth > 0) {
			const maxWidth = ((window.innerWidth - circleWrapWidth) / 2) - 40

			setSlideshowHeight(slideshowRef.current.clientHeight)
			setMaxWidth(maxWidth)
		}
	}, [circleWrapWidth])

	if (sponsors.length === 0) {
		return null
	}

	if (circleWrapWidth > 0) {
		return (
			<Flex
				className="conf-mode-sponsors"
				ref={slideshowRef}
				pos="absolute"
				top={0}
				left={leftSide ? 0 : undefined}
				right={!leftSide ? 0 : undefined}
				bottom={0}
				w={`${maxWidth}px`}
				direction="column"
				justify="center"
				overflow="hidden"
			>
				<Slider
					pauseOnHover={false}
					autoplay={true}
					autoplaySpeed={speed}
					speed={3000}
					infinite={true}
					vertical={true}
					slidesToShow={1}
					slidesToScroll={1}
					nextArrow={<></>}
					prevArrow={<></>}
					fade={true}
				>
					{sponsors.map((sponsor) => {
						const { id, imgUrl, bgColor } = sponsor

						return (
							<Flex
								key={id}
								pos="relative"
								bgGradient={`linear(to-b, black, ${bgColor}, ${bgColor}, black)`}
								h={`${slideshowHeight}px`}
								top={0}
							>
								<Image
									boxSize={`${edgeToEdge ? maxWidth : maxWidth - 36}px`}
									height="100%"
									objectFit="contain"
									src={imgUrl}
									pos="absolute"
									top={0}
									left={0}
									right={0}
									bottom={0}
									m="auto"
								/>
							</Flex>
						)
					})}
				</Slider>
			</Flex>
		)
	}

	return null
}
