import { useState, useEffect } from 'react'
import {
	Flex,
	Text,
	type ResponsiveValue,
} from '@chakra-ui/react'
import { format, add } from 'date-fns'
import { useRecoilValue } from 'recoil'

import {
	blockState,
	staticModeState,
	circlesBreakpointState,
	showBlockRippleState,
	appInitializedState,
	landscapeOrientationState,
} from '../../../state'
import {
	getBlocksToHalving,
	getBreakpointValue,
	getTimeFormat,
	getAmPm,
	getCountdownString,
} from '../../../utils'
import { useAppSelector } from '../../../hooks'
import { Label } from '../../shared'
import { dateLocales } from '../../../lang/dateFnsLocales'
import { BITCOIN_ORANGE, EPOCH_5_BLOCK } from '../../../constants'

interface HalvingCountdownProps {
	posTop?: number | string
}

export const HalvingCountdown = ({ posTop }: HalvingCountdownProps) => {
	const { userLocale, timeFormat } = useAppSelector(({ settings }) => settings)
	const circlesBreakpoint = useRecoilValue(circlesBreakpointState)
	const appInitialized = useRecoilValue(appInitializedState)
	const staticMode = useRecoilValue(staticModeState)
	const landscapeOrientation = useRecoilValue(landscapeOrientationState)
	const showBlockRipple = useRecoilValue(showBlockRippleState)
	const block = useRecoilValue(blockState)
	const blockHeight = block.height
	const halvingBlocks = getBlocksToHalving(blockHeight)
	const [countdown, setCountdown] = useState('')
	const [halvingDate, setHalvingDate] = useState<Date | null>(null)

	const halvingAmPm = halvingDate ? `${getAmPm(halvingDate, timeFormat)}` : ''
	const estHalvingDateString = halvingDate
		? `${format(halvingDate, `LLL d, yyy ~${getTimeFormat(timeFormat)}`, { locale: dateLocales[userLocale] })}${halvingAmPm}`
		: ''

	const displayCountdown = !staticMode
		&& appInitialized
		&& blockHeight > 0
		&& halvingBlocks > 0
		&& halvingBlocks < 10000
		&& countdown !== ''
	const countdownOpacity = showBlockRipple ? 0 : 1

	const countdownFontSize = getBreakpointValue({ base: 'md', md: 'lg', xl: 'xl', xxl: '2xl' }, circlesBreakpoint)
	const consitionalPos = landscapeOrientation ? 'absolute' : 'relative'
	const countdownPosition = getBreakpointValue({ base: consitionalPos, md: 'absolute' }, circlesBreakpoint) as ResponsiveValue<'relative' | 'absolute'>
	const countdownEstFontSize = getBreakpointValue({ base: '12px', xxxl: '13px' }, circlesBreakpoint)

	useEffect(() => {
		if (halvingBlocks > 0 && blockHeight > 0) {
			const estHalvingDate = add(new Date(), { minutes: 10 * halvingBlocks })
			setHalvingDate(estHalvingDate)
			setCountdown(getCountdownString(estHalvingDate))
		}
	}, [halvingBlocks])

	useEffect(() => {
		const timer = setInterval(() => {
			if (halvingDate) {
				setCountdown(getCountdownString(halvingDate))
			}
		}, 60000)

		return () => {
			clearInterval(timer)
		}
	}, [halvingDate])

	if (blockHeight > (EPOCH_5_BLOCK - 1)) {
		return null
	}

	return (
		<>
			{displayCountdown && (
				<Flex
					className="halving-countdown"
					direction="column"
					align="center"
					pos={countdownPosition}
					top={posTop}
					left={0}
					right={0}
					margin="auto"
					opacity={countdownOpacity}
					transition="opacity 0.45s ease"
					zIndex={999}
				>
					<Label>
						Halving Countdown
					</Label>

					<Text 
						fontSize={countdownFontSize}
						fontWeight="bold"
						textAlign="center"
						lineHeight={1.2}
						color={BITCOIN_ORANGE}
					>
						~ {countdown}
					</Text>

					<Label
						fontSize={countdownEstFontSize}
						fontWeight="bold"
						lineHeight={1}
						color="whiteAlpha.700"
					>
						{estHalvingDateString}
					</Label>
				</Flex>
			)}
		</>
	)
}
