export type BitcoinDateAlert = {
	label: string
	date: string
	year?: number
	description?: string
	link?: string
	bDay?: boolean
}

export const GM_CONFIG = {
	hour: 6,
	durationHours: 4,
}

export const buttonBreaks = {
	base: '30px',
	xs: '32px',
	sm: '37px',
	md: '40px',
	lg: '42px',
	xl: '48px',
	xxl: '54px',
	xxxl: '60px',
}
export const buttonIconSize = {
	base: 4,
	sm: 5,
	xl: 6,
	xxl: 7,
}
export const buttonBottom = {
	base: '29%',
	xs: '27.5%',
	sm: '22.4%',
	md: '22.75%',
	xl: '22.5%',
}
