import type { PropsWithChildren } from 'react'
import { Flex, Image, type ResponsiveValue } from '@chakra-ui/react'
import { useRecoilValue } from 'recoil'

import {
	editingBlockHeightState,
	showBlockRippleState,
	staticModeState,
	circlesBreakpointState,
	confModeState,
	confImgState,
	confImgTopState,
} from '../../../state'
import { useAppSelector } from '../../../hooks'
import { getBreakpointValue } from '../../../utils'
import { blurInAnimation } from '../../../constants'

export const Lower = ({ children, ...rest }: PropsWithChildren) => {
	const confMode = useRecoilValue(confModeState)
	const confImg = useRecoilValue(confImgState)
	const confImgTop = useRecoilValue(confImgTopState)
	const circlesBreakpoint = useRecoilValue(circlesBreakpointState)
	const staticMode = useRecoilValue(staticModeState)
	const showBlockRipple = useRecoilValue(showBlockRippleState)
	const editingBlockHeight = useRecoilValue(editingBlockHeightState)
	const { showFees } = useAppSelector(({ settings }) => settings)

	const responsiveDisplay = getBreakpointValue({ base: 'none', sm: 'flex' }, circlesBreakpoint) as ResponsiveValue<'none' | 'flex'>
	const responsiveTop = getBreakpointValue({
		base: undefined,
		sm: '62.3%',
		md: '62.2%',
		lg: '62%',
		xl: '61%',
	}, circlesBreakpoint)
	const responsiveLeft = getBreakpointValue({ base: undefined, sm: '11%' }, circlesBreakpoint)
	const responsivePos = getBreakpointValue({ base: 'relative', sm: 'absolute' }, circlesBreakpoint) as ResponsiveValue<'relative' | 'absolute'>
	const responsivePx = getBreakpointValue({ base: 4, sm: 0 }, circlesBreakpoint)

	const shouldBlur = staticMode || showBlockRipple || editingBlockHeight
	const opacity = shouldBlur ? 0.21 : 1
	const filter = shouldBlur ? 'blur(4px)' : 'blur(0)'

	if (!showFees) return null

	return (
		<Flex
			className="tc-lower-section"
			display={responsiveDisplay}
			direction="column"
			align="center"
			pos={responsivePos}
			top={responsiveTop}
			left={responsiveLeft}
			right={responsiveLeft}
			px={responsivePx}
			zIndex={999}
			{...rest}
		>
			{children}

			{confMode && confImg && (
				<Flex
					pos="relative"
					zIndex={99}
					w="100%"
					justify="center"
					mt={`${confImgTop}%`}
					opacity={opacity}
					filter={filter}
					animation={blurInAnimation}
					transition="all 0.45s ease"
				>
					<Image
						src={confImg}
						boxSize="56%"
					/>
				</Flex>
			)}
		</Flex>
	)
}
