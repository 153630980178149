import { useLayoutEffect, useRef } from 'react'
import { Box } from '@chakra-ui/react'
import { gsap } from 'gsap'

export const AsicGrill = () => {
	const asicGrillRef = useRef<HTMLDivElement>(null)
	const grillDimension = window.innerWidth > 600 && window.innerHeight > 600
		? '280px'
		: '150px'

	useLayoutEffect(() => {
		const ctx = gsap.context(() => {
			const tl = gsap.timeline()
			tl.to(asicGrillRef.current, {
				rotation: '360deg',
				duration: 0.95,
				repeat: -1,
				ease: 'easeInOut',
			})
		})

		return () => ctx.revert()
	}, [])

	return (
		<Box
			pos="relative"
			zIndex={0}
			w={grillDimension}
			h={grillDimension}
			maxW="80%"
			maxH="80%"
			// top={0}
			// left={0}
			// right={0}
			// bottom={0}
			// m="auto"
			borderRadius="50%"
			overflow="hidden"
		>
			<Box
				ref={asicGrillRef}
				pos="absolute"
				top={0}
				left={0}
				opacity={0.9}
				backgroundImage="url(/images/asic-grill.webp)"
				backgroundPosition="center center"
				backgroundSize="cover"
				w="100%"
				h="100%"
			/>
		</Box>
	)
}
