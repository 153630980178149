export const getWhitepaperUrl = (locale: string) => {
	const baseUrl = 'https://bitcoin.org'
	const whitepaperBaseUrl = `${baseUrl}/files/bitcoin-paper`
	switch (locale) {
		case 'ar':
			return `${whitepaperBaseUrl}/bitcoin_ar.pdf`
		case 'de':
			return `${whitepaperBaseUrl}/bitcoin_de.pdf`
		case 'es':
			return `${whitepaperBaseUrl}/bitcoin_es.pdf`
		case 'fr':
			return `${whitepaperBaseUrl}/bitcoin_fr.pdf`
		case 'gr':
			return `${whitepaperBaseUrl}/bitcoin_gr.pdf`
		case 'it':
			return `${whitepaperBaseUrl}/bitcoin_it.pdf`
		case 'pl':
			return `${whitepaperBaseUrl}/bitcoin_pl.pdf`
		case 'pt':
			return `${whitepaperBaseUrl}/bitcoin_pt.pdf`
		case 'ru':
			return `${whitepaperBaseUrl}/bitcoin_ru.pdf`
		case 'sv':
			return `${whitepaperBaseUrl}/bitcoin_se.pdf`
		case 'tr':
			return `${whitepaperBaseUrl}/bitcoin_tr.pdf`
		case 'zh':
			return `${whitepaperBaseUrl}/bitcoin_zh_cn.pdf`
		default:
			return `${baseUrl}/bitcoin.pdf`
	}
}