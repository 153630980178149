import {
	useLayoutEffect,
	useState,
	useRef,
} from 'react'
import { Box } from '@chakra-ui/react'
import { gsap } from 'gsap'
import { useRecoilValue } from 'recoil'

import { useAppSelector } from '../../../hooks'
import { circlesBreakpointState } from '../../../state'
import { BITCOIN_ORANGE } from '../../../constants'
import { blocksToHalvingOffset } from './circles.constants'
import { getBreakpointValue } from '../../../utils'

interface BlocksToHalvingRippleProps {
	// eslint-disable-next-line no-unused-vars
	setShowBlockRipple: (x: boolean) => void
}

const Ripple = ({ ...rest }) => {
	const circlesBreakpoint = useRecoilValue(circlesBreakpointState)
	const responsiveBorder = getBreakpointValue({ base: '4px', md: '6px', lg: '8px' }, circlesBreakpoint)

	return (
		<Box
			className="ripple"
			opacity={0}
			position="absolute"
			top={0}
			left={0}
			right={0}
			bottom={0}
			borderRadius="50%"
			borderWidth={responsiveBorder}
			borderColor={BITCOIN_ORANGE}
			transformOrigin="center"
			willChange="opacity"
			{...rest}
		/>
	)
}

export const BlocksToHalvingRipple = ({ setShowBlockRipple }: BlocksToHalvingRippleProps) => {
	const { reducedMotion, fullNewBlockRipple } = useAppSelector(({ settings }) => settings)
	const circlesBreakpoint = useRecoilValue(circlesBreakpointState)
	const [animationComplete, setAnimationComplete] = useState(false)
	const rippleWrapRef = useRef<HTMLDivElement>(null)
	const size = getBreakpointValue(blocksToHalvingOffset, circlesBreakpoint)

	useLayoutEffect(() => {
		const ctx = gsap.context(() => {
			if (rippleWrapRef.current && !animationComplete) {
				const tl = gsap.timeline()
				tl.to('.ripple', {
					opacity: 1,
					duration: 0.75,
					ease: 'easeInOut',
					stagger: {
						each: fullNewBlockRipple ? 0.25 : 0.45,
					},
					delay: 0.45,
				})
				tl.to('.ripple', {
					opacity: 0,
					duration: 2.45,
					ease: 'easeInOut',
					delay: 0.8,
					stagger: {
						each: 0.25,
					},
					onComplete: () => {
						setAnimationComplete(true)
						setShowBlockRipple(false)
					},
				}, '<').repeat(2)
			}
		}, rippleWrapRef)

		return () => ctx.revert()
	}, [
		animationComplete,
		setShowBlockRipple,
	])

	return (
		<Box
			ref={rippleWrapRef}
			pos="absolute"
			top={size}
			left={size}
			right={size}
			bottom={size}
			zIndex={997}
		>
			{!reducedMotion && (
				<>
					<Ripple transform="scale(1.1)" />
					<Ripple transform="scale(1.2)" />

					{fullNewBlockRipple && (
						<>
							<Ripple transform="scale(1.3)" />
							<Ripple transform="scale(1.4)" />
							<Ripple transform="scale(1.5)" />
							<Ripple transform="scale(1.6)" />
						</>
					)}
				</>
			)}
		</Box>
	)
}
