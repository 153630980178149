import { createSlice, type PayloadAction } from '@reduxjs/toolkit'

interface GmAlertState {
	gmDismissed: boolean
	gmDismissedDate: string
	gmTodayDate: string
	gmTodayMessage: string
}

const initialState = {
	gmDismissed: false,
	gmDismissedDate: '',
	gmTodayDate: '',
	gmTodayMessage: '',
} as GmAlertState

export const gmAlertSlice = createSlice({
	name: 'gmAlert',
	initialState,
	reducers: {
		setGmDismissed: (state, action: PayloadAction<boolean>) => {
			state.gmDismissed = action.payload
		},
		setGmDismissedDate: (state, action: PayloadAction<string>) => {
			state.gmDismissedDate = action.payload
		},
		setGmTodayDate: (state, action: PayloadAction<string>) => {
			state.gmTodayDate = action.payload
		},
		setGmTodayMessage: (state, action: PayloadAction<string>) => {
			state.gmTodayMessage = action.payload
		},
	},
})

export const {
	setGmDismissed,
	setGmDismissedDate,
	setGmTodayDate,
	setGmTodayMessage,
} = gmAlertSlice.actions

export default gmAlertSlice.reducer
