import {
	Flex,
	Image,
	Modal,
	ModalContent,
	ModalFooter,
	ModalBody,
	Button,
	useDisclosure,
	type FlexProps,
	useColorModeValue,
} from '@chakra-ui/react'

import { BITCOIN_ORANGE } from '../../../constants'
import { CloseButtonLabel, ModalOverlay } from '../../shared'
import { BTCPayForm } from './BTCPayForm'
import { TCDonateButton } from './TCDonateButton'

interface TCDonateProps {
	isHeader?: boolean
}

type TCDonateCombinedProps = TCDonateProps & FlexProps

export const TCDonate = ({ isHeader = false, ...rest }: TCDonateCombinedProps) => {
	const { isOpen, onOpen, onClose } = useDisclosure()
	const bg = useColorModeValue('white', 'black')
	const color = useColorModeValue('black', 'white')
	const colorAltBg = useColorModeValue('rgba(0,0,0,0.5)', 'transparent')

	const interactionStyles = {
		borderColor: BITCOIN_ORANGE,
		color: BITCOIN_ORANGE,
	}

	return (
		<>
			<TCDonateButton
				onClick={onOpen}
				isHeader={isHeader}
				{...rest}
			/>

			<Modal
				key="donate"
				isOpen={isOpen}
				onClose={onClose}
				isCentered
				motionPreset="slideInBottom"
				size="sm"
			>
				<ModalOverlay />

				<ModalContent
					bg={bg}
					borderWidth={1}
					borderColor={color}
					color={color}
					borderRadius="lg"
					zIndex={3334}
				>
					<ModalBody mt={4}>
						<BTCPayForm onClose={onClose} />
					</ModalBody>

					<ModalFooter>
						<Flex
							w="100%"
							justify="space-between"
							align="flex-end"
						>
							<Flex
								fontSize="xs"
								color="whiteAlpha.700"
								align="center"
								gap={2}
								p={1}
								bg={colorAltBg}
							>
								Powered by <Image
									src="https://btcpay722165.lndyn.com/img/paybutton/logo.svg"
									display="inline-block"
									boxSize="60px auto"
									htmlHeight="auto"
									htmlWidth="60px"
								/>
							</Flex>

							<Button
								onClick={onClose}
								variant="outline"
								colorScheme="black"
								fontSize="sm"
								borderWidth={2}
								textTransform="uppercase"
								_active={interactionStyles}
								_hover={interactionStyles}
							>
								<CloseButtonLabel />
							</Button>
						</Flex>
					</ModalFooter>
				</ModalContent>
			</Modal>
		</>
	)
}
