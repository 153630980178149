interface GalleryIconProps {
	width?: number | string
}

export const GalleryIcon = ({ width = 200 }: GalleryIconProps) => {
	return (
		<svg
			width={width}
			viewBox="0 0 200 200"
			fill="currentColor"
		>
			<path d="M150.93 161.91H11V57.98c2.71-1.23 5.41-3.51 8.13-3.52 42.64-.22 85.29-.17 127.93-.15 6.49 0 7.97 1.51 7.98 7.9.02 30.48-.06 60.95.09 91.43.02 3.75-1.13 6.36-4.2 8.27zm-134.27-43.9c3.06-2.89 4.88-4.82 6.92-6.5 4.32-3.56 10.23-3.87 14.19-.15 6.17 5.78 11.94 11.98 18.79 18.93-8.68 8.6-17.24 17.08-25.71 25.46 3.94 1.17 6.88-.45 9.85-3.46 19.44-19.66 39.02-39.19 58.62-58.69 7.14-7.1 15.21-7.05 22.43-.04 3.7 3.6 7.34 7.27 10.96 10.95 5.31 5.4 10.59 10.84 16.36 16.75.45-1.56.68-1.99.68-2.42.02-17.49.04-34.97.02-52.46 0-4.53-2.28-6.56-6.86-6.55-39.81.05-79.63.06-119.44-.01-4.93-.01-6.94 2.22-6.86 6.98.1 6.33.02 12.66.02 18.99.03 10.26.03 20.53.03 32.22z" />
			<path d="M53.98 28c11.08 1.98 22.13 4.1 33.24 5.91 31.67 5.16 63.37 10.13 95.03 15.38 2.36.39 4.45 2.42 6.67 3.69v4c-2.3 13.25-4.7 26.48-6.89 39.74-2.85 17.23-5.51 34.49-8.29 51.73-.97 6-2.77 7.26-8.89 6.32-2.12-.33-4.22-.81-6.76-1.3-.08-1.56-.16-3.12-.26-5.03 2.33.17 4.72 1.07 6.07.26 2.1-1.26 4.65-3.5 5.02-5.66 4.66-27.21 8.96-54.48 13.37-81.74.73-4.51-1.11-6.81-5.66-7.54-30.56-4.86-61.1-9.82-91.64-14.75-8.38-1.35-16.74-2.78-25.14-3.99-6.56-.94-7.97.2-9.02 6.63-.35 2.13-.79 4.26-.98 6.41-.31 3.56-2.11 4.54-6.15 3.14 2.57-7.6.26-16.38 6.29-23.21 1.32.01 2.66.01 3.99.01zM57.24 82.75c.09 7.1-5.39 12.75-12.41 12.78-6.91.03-12.74-5.77-12.7-12.64.04-6.7 5.55-12.32 12.3-12.54 6.97-.22 12.72 5.35 12.81 12.4z" />
		</svg>
	)
}
