import { Box, useColorModeValue } from '@chakra-ui/react'
import { useRecoilValue } from 'recoil'

import { getBreakpointValue } from '../../../utils'
import { circlesBreakpointState } from '../../../state'
import { blocksToHalvingShadowOffset, blocksToHalvingOffset } from './circles.constants'

interface HalvingCircleShadowProps {
	circleClientWidth: number
}

export const HalvingCircleShadow = ({ circleClientWidth }: HalvingCircleShadowProps) => {
	const circlesBreakpoint = useRecoilValue(circlesBreakpointState)
	const shadowBg = useColorModeValue('rgba(27,15,0,0.7)', 'rgba(27,15,0,1)')
	const shadowBg2 = useColorModeValue('rgba(97,54,1,0.75)', 'rgba(97,54,1,0.8)')
	
	const responsiveCircleOffset = getBreakpointValue(blocksToHalvingOffset, circlesBreakpoint)
	const responsiveCircleShadowOffset = getBreakpointValue(blocksToHalvingShadowOffset, circlesBreakpoint)

	return (
		<>
			<Box
				className="halving-circle-shadow"
				pos="absolute"
				bottom={responsiveCircleOffset}
				left={responsiveCircleShadowOffset}
				w={circleClientWidth}
				h={circleClientWidth * 0.1}
				borderRadius="50%"
				filter="blur(20px)"
				bg={shadowBg}
				opacity={0.2}
				transform="scale(0.9) translateY(20px)"
			/>
			<Box
				className="halving-circle-shadow-darker"
				pos="absolute"
				bottom={responsiveCircleOffset}
				left={responsiveCircleShadowOffset}
				w={circleClientWidth}
				h={circleClientWidth * 0.05}
				borderRadius="50%"
				filter="blur(15px)"
				bg={shadowBg2}
				opacity={0.25}
				transform="scale(0.6) translateY(24px)"
			/>
		</> 
	)
}
