import {
	Flex,
	Text,
	Modal,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalBody,
	Button,
	useDisclosure,
	type FlexProps,
	useColorModeValue,
} from '@chakra-ui/react'
import { FormattedMessage } from 'react-intl'
import { SettingsIcon } from '@chakra-ui/icons'

import { useAppSelector } from '../../../hooks'
import { CloseButtonLabel, ModalOverlay } from '../../shared'
import { BITCOIN_ORANGE } from '../../../constants'
import { TCSettingsButton } from './TCSettingsButton'
import { TCSettingsUserPreferences } from './TCSettingsUserPreferences'
import { rtlLocales } from '../../../lang/messages'
import { TCConfModeModal } from '../conf-mode'

export type ConversionPref = 'MT' | 'CB'
export const TC_SETTINGS_LOCAL_STORAGE_ID = 'tc-settings'

export const TCSettings = ({ ...rest }: FlexProps) => {
	const { isOpen, onOpen, onClose } = useDisclosure()
	const {
		isOpen: isOpenConfMode,
		onClose: onCloseConfMode,
	} = useDisclosure()
	const bg = useColorModeValue('white', 'black')
	const color = useColorModeValue('black', 'white')
	const colorAlt = useColorModeValue('rgba(0,0,0,0.7)', 'rgba(255,255,255,0.7)')
	const colorAlt2 = useColorModeValue('rgba(0,0,0,0.5)', 'rgba(255,255,255,0.5)')

	// @ts-ignore
	const { userLocale } = useAppSelector(({ settings }) => settings)
	const direction = rtlLocales.includes(userLocale) ? 'rtl' : undefined

	return (
		<>
			<TCSettingsButton onClick={onOpen} {...rest} />

			<Modal
				key="settings"
				isOpen={isOpen}
				onClose={onClose}
				isCentered
				motionPreset="slideInBottom"
				size="lg"
			>
				<ModalOverlay />

				<ModalContent
					bg={bg}
					borderWidth={1}
					borderColor={color}
					borderRadius="lg"
					zIndex={3334}
					dir={direction}
				>
					<ModalHeader pb={0}>
						<Flex align="center" gap="1">
							<SettingsIcon color={colorAlt2} />
							<Text
								color={colorAlt}
								fontSize="xs"
								textTransform="uppercase"
							>
								<FormattedMessage id="settings.modal_title" />
							</Text>
						</Flex>
					</ModalHeader>

					<ModalBody>
						<TCSettingsUserPreferences />
					</ModalBody>

					<ModalFooter>
						<Flex
							w="100%"
							justify="space-between"
							align="center"
							gap={8}
							color={color}
						>
							<Text
								fontSize="xxs"
								fontStyle="italic"
								lineHeight="shorter"
								color={colorAlt}
							>
								<FormattedMessage id="settings.modal_disclaimer" />
							</Text>

							<Button
								onClick={onClose}
								flexShrink={0}
								variant="outline"
								colorScheme="black"
								fontSize="sm"
								textTransform="uppercase"
								_active={{
									borderColor: BITCOIN_ORANGE,
									color: BITCOIN_ORANGE,
								}}
								_hover={{
									borderColor: BITCOIN_ORANGE,
									color: BITCOIN_ORANGE,
								}}
							>
								<CloseButtonLabel />
							</Button>
						</Flex>
					</ModalFooter>
				</ModalContent>
			</Modal>

			<TCConfModeModal
				isOpenConfMode={isOpenConfMode}
				onCloseConfMode={onCloseConfMode}
			/>
		</>
	)
}
