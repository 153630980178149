import {
	Flex,
	chakra,
	shouldForwardProp,
	useColorModeValue,
} from '@chakra-ui/react'
import { motion, isValidMotionProp } from 'framer-motion'
import { ArrowLeftIcon, ArrowRightIcon } from '@chakra-ui/icons'
import { FormattedMessage } from 'react-intl'

import { Label } from '../../shared'
import { getBreakpointValue } from '../../../utils'
import { useRecoilValue } from 'recoil'
import { circlesBreakpointState } from '../../../state'

interface BlockScrubLabelProps {
	staticMode: boolean
	scrubHeight: number
}

const MotionBox = chakra(motion.div, {
	shouldForwardProp: (prop) => isValidMotionProp(prop) || shouldForwardProp(prop),
})

export const BlockScrubLabel = ({ staticMode, scrubHeight }: BlockScrubLabelProps) => {
	const circlesBreakpoint = useRecoilValue(circlesBreakpointState)
	const fontSize = getBreakpointValue({ base: '9px', md: '10px', jumbo: '12px' }, circlesBreakpoint)
	const arrowSize = getBreakpointValue({ base: '7px', md: '8px' }, circlesBreakpoint)
	const gapSize = getBreakpointValue({ base: 1, md: 1 }, circlesBreakpoint)
	const colorAlt = useColorModeValue('rgba(0,0,0,0.7)', 'rgba(255,255,255,0.7)')
	const colorAlt2 = useColorModeValue('rgba(0,0,0,0.3)', 'rgba(255,255,255,0.3)')

	return (
		<Flex justify="center" align="center">
			{!staticMode && (
				<>
					<Flex align="center" lineHeight="none">
						{scrubHeight > 0 && (
							<ArrowLeftIcon
								color={colorAlt}
								w={arrowSize}
								h={arrowSize}
							/>
						)}
					</Flex>
					<Label
						fontWeight="semibold"
						fontSize={fontSize}
						lineHeight="none"
						color={colorAlt}
						px={gapSize}
					>
						<FormattedMessage id="slider.scrub_blocks" />
					</Label>
					<Flex align="center" lineHeight="none">
						<ArrowRightIcon
							color={colorAlt2}
							w={arrowSize}
							h={arrowSize}
						/>
					</Flex>
				</>
			)}

			{staticMode && (
				<MotionBox
					display="flex"
					alignItems="center"
					animate={{
						opacity: [1, 0.7],
					}}
					// @ts-ignore
					transition={{
						duration: 1,
						ease: 'linear',
						repeat: Infinity,
						repeatType: 'loop',
					}}
				>
					<Flex align="center" lineHeight="none">
						<ArrowLeftIcon
							color={scrubHeight > 0 ? colorAlt : colorAlt2}
							w={arrowSize}
							h={arrowSize}
						/>
					</Flex>
					<Label
						fontWeight="semibold"
						fontSize={fontSize}
						lineHeight="none"
						color={colorAlt}
						px={gapSize}
					>
						<FormattedMessage id="slider.scrub_blocks" />
					</Label>
					<Flex align="center" lineHeight="none">
						<ArrowRightIcon
							color={colorAlt}
							w={arrowSize}
							h={arrowSize}
						/>
					</Flex>
				</MotionBox>
			)}
		</Flex>
	)
}