import { createSlice, type PayloadAction } from '@reduxjs/toolkit'

import type { UserCurrency } from '../currency'

export interface CurrenciesState {
	userCurrency: UserCurrency
}

const initialState = {
	userCurrency: 'usd',
} as CurrenciesState

export const currenciesSlice = createSlice({
	name: 'currencies',
	initialState,
	reducers: {
		setUserCurrency: (state, action: PayloadAction<UserCurrency>) => {
			state.userCurrency = action.payload
		},
	}
})

export const {
	setUserCurrency,
} = currenciesSlice.actions

export default currenciesSlice.reducer
