import {
	Flex,
	Box,
	Text,
	Button,
	Modal,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalBody,
	useColorModeValue,
} from '@chakra-ui/react'
import { FormattedMessage, useIntl } from 'react-intl'

import { AnimatedBitcoinB } from './CalendarAlerts.components'
import { BITCOIN_ORANGE, type BitcoinDateAlert } from '../../../constants'
import { rtlLocales } from '../../../lang/messages'
import { useAppSelector } from '../../../hooks'
import { ModalOverlay } from '../../shared'

interface CalendarAlertsProps {
	alert: BitcoinDateAlert
	isOpen: boolean
	onClose: () => void
}

export const defaultCalAlert = {
	alert: {
		date: '',
		label: '',
	},
	dismissed: false,
}

export const CalendarAlert = ({ alert, isOpen, onClose }: CalendarAlertsProps) => {
	const intl = useIntl()
	const bg = useColorModeValue('white', 'black')
	const color = useColorModeValue('black', 'white')
	const colorAlt = useColorModeValue('rgba(0,0,0,0.7)', 'rgba(255,255,255,0.7)')

	const {
		year,
		label,
		description,
		link,
		// bDay,
	} = alert
	const introText = year
		? `${intl.formatMessage({ id: 'alerts.bitcoin_dates.on_this_day' })} ${year}, `
		: ''
	const text = `${introText}${description}`

	// @ts-ignore
	const { userLocale } = useAppSelector(({ settings }) => settings)
	const direction = rtlLocales.includes(userLocale) ? 'rtl' : undefined

	return (
		<Modal
			key="settings"
			isOpen={isOpen}
			onClose={onClose}
			isCentered
			motionPreset="slideInBottom"
		>
			<ModalOverlay />

			<ModalContent
				bg={bg}
				borderWidth={1}
				borderColor={color}
				color={color}
				borderRadius="lg"
				zIndex={3334}
				dir={direction}
			>
				<ModalHeader pb={0}>
					<Flex align="center" gap="2">
						<AnimatedBitcoinB />
						<Text
							color={colorAlt}
							fontSize="xs"
							textTransform="uppercase"
						>
							Calendar Alert
						</Text>
					</Flex>
				</ModalHeader>

				<ModalBody pb={4}>
					<Text
						align="center"
						fontWeight="bold"
						mb={2}
						color={BITCOIN_ORANGE}
					>
						{label}
					</Text>
					<Text
						pos="relative"
						zIndex={1999}
						textAlign="center"
						maxW="400px"
					>
						{text}
					</Text>
				</ModalBody>

				<ModalFooter>
					<Flex
						w="100%"
						justify="flex-end"
						align="center"
						gap={2}
						color={color}
					>
						{link && (
							<Button
								as="a"
								variant="outline"
								pos="relative"
								zIndex={1999}
								href={link}
								target="_blank"
								fontSize="sm"
								fontWeight="semibold"
								textTransform="uppercase"
								borderWidth={2}
								borderColor={BITCOIN_ORANGE}
								color={BITCOIN_ORANGE}
								cursor="pointer"
								_hover={{
									bg: 'black',
								}}
							>
								<FormattedMessage id="alerts.bitcoin_dates.learn_more" />
							</Button>
						)}

						<Button
							bg={bg}
							onClick={onClose}
							variant="outline"
							colorScheme="black"
							pos="relative"
							zIndex={1999}
							fontSize="sm"
							borderWidth={2}
							textTransform="uppercase"
							cursor="pointer"
							_hover={{
								borderColor: BITCOIN_ORANGE,
								color: BITCOIN_ORANGE,
							}}
						>
							<FormattedMessage id="shared.close_button_label" />
						</Button>
					</Flex>
				</ModalFooter>
			</ModalContent>
			<Flex
				pos="relative"
				h="100%"
				w="100%"
				direction="column"
				justify="center"
				align="center"
				p="0 36px 16px"
				textShadow="black 0px 0px 10px"
			>
				<Box
					pos="absolute"
					w="75%"
					h="75%"
					bg={bg}
					borderRadius="50%"
					filter="blur(15px)"
				/>
				<Flex
					pos="relative"
					zIndex={1999}
					direction="column"
					align="center"
				>
					<AnimatedBitcoinB />
				</Flex>

				{/* {bDay && <Candles />} */}
			</Flex>
		</Modal>
	)
}
