export const CONVERSION = {
	mt: 'MT',
	cb: 'CB',
	both: 'BOTH',
	none: 'NONE',
}

export type TimeFormat = 'h12' | 'h24'
export const TIME_FORMAT = {
	h12: '12H',
	h24: '24H',
}

export const MILLISECONDS_IN_24HOURS = 86400000

export type DateFormat = 'DEFAULT' | 'ALT' | 'EUROPE' | 'UNIVERSAL'
export const DATE_FORMAT = {
	default: 'DEFAULT',
	alt: 'ALT',
	europe: 'EUROPE',
	universal: 'UNIVERSAL',
}

export const FORMATS_CONFIG = {
	universalDate: 'y-LL-dd',
	europeDate: 'dd-LL-y',
	defaultDate: 'LLL d, y',
	altDate: 'd LLL y',

	universalMonthDay: 'LL-dd',
	europeMonthDay: 'dd-LL',
	defaultMonthDay: 'LLL d',
	altMonthDay: 'd LLL',

	defaultDay: 'EEEE',
	timeH24: 'H:mm',
	defaultTime: 'h:mm',
	amPm: 'aa'
}
