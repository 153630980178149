import { atom } from 'recoil'

export const maxMempoolState = atom({
	key: 'maxmempool',
	default: 0,
})

export const mempoolMinFeeState = atom({
	key: 'mempoolminfee',
	default: 0,
})

export const mempoolSizeState = atom({
	key: 'mempoolsize',
	default: 0,
})

export const mempoolUsageState = atom({
	key: 'mempoolusage',
	default: 0,
})

export const mempoolBlocksState = atom({
	key: 'mempoolBlocks',
	default: 0,
})

export const txVBytesPerSecondState = atom({
	key: 'txVBytesPerSecond',
	default: 0,
})
