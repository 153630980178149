import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import type { Sponsor } from './SponsorSelector'

interface SponsorsState {
	sponsors: Sponsor[]
	showSlideshow: boolean
	edgeToEdge: boolean
	slideshowTiming: number
}

const initialState = {
	sponsors: [],
	showSlideshow: true,
	edgeToEdge: false,
	slideshowTiming: 10,
} as SponsorsState

export const sponsorsSlice = createSlice({
	name: 'confModeSponsors',
	initialState,
	reducers: {
		setSponsors: (state, action: PayloadAction<Sponsor[]>) => {
			state.sponsors = action.payload
		},
		setShowSlideshow: (state, action: PayloadAction<boolean>) => {
			state.showSlideshow = action.payload
		},
		setEdgeToEdge: (state, action: PayloadAction<boolean>) => {
			state.edgeToEdge = action.payload
		},
		setSlideshowTiming: (state, action: PayloadAction<number>) => {
			state.slideshowTiming = action.payload
		},
	},
})

export const {
	setSponsors,
	setShowSlideshow,
	setEdgeToEdge,
	setSlideshowTiming,
} = sponsorsSlice.actions

export default sponsorsSlice.reducer
