// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const flattenMessages = (nestedMessages: any, prefix = '') => {
	return Object.keys(nestedMessages).reduce((messages, key) => {
		const value = nestedMessages[key]
		const prefixedKey = prefix ? `${prefix}.${key}` : key

		if (typeof value === 'string') {
			// @ts-ignore
			messages[prefixedKey] = value
		} else {
			Object.assign(messages, flattenMessages(value, prefixedKey))
		}

		return messages
	}, {})
}

type ValueKeyObject = { [key: string]: number }

export const getKeyByValue = (valueKeyObj: ValueKeyObject, maxVal: number): string => {
	const allKeys = Object.entries(valueKeyObj)
	allKeys.sort((a, b) => a[1] - b[1])

	const defaultKey = allKeys[0][0]

	// eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
	const suitableKeys = allKeys.filter(([_, value]) => value <= maxVal)
	suitableKeys.sort((a, b) => b[1] - a[1])

	return suitableKeys.length > 0 ? suitableKeys[0][0] : defaultKey
}
