import {
	useState,
	useEffect,
	useLayoutEffect,
	useRef,
	useMemo,
} from 'react'
import {
	Flex,
	Box,
	Text,
	chakra,
	shouldForwardProp,
	useColorModeValue,
	useToken,
	type ResponsiveValue,
} from '@chakra-ui/react'
import { motion, isValidMotionProp } from 'framer-motion'
import { useIntl } from 'react-intl'
import {
	useRecoilValue,
	useRecoilState,
	useSetRecoilState,
} from 'recoil'
import { gsap } from 'gsap'

import {
	blockState,
	staticModeState,
	circlesWrapWidthState,
	circlesBreakpointState,
	scrubBlockState,
	showBlockRippleState,
	landscapeOrientationState,
	halvingIntroCompleteState,
	halvingIntroStartState,
} from '../../../state'
import { useAppSelector } from '../../../hooks'
import { CircleWrap } from './CircleWrap'
import { TopMark } from './TopMark'
import { TextPathCircle } from './TextPathCircle'
import { CircleIndicatorArrow } from './CircleIndicatorArrow'
import { EPOCH_BLOCKS } from '../../../constants'
import {
	getRotationAngle,
	getBlocksToHalving,
	getBreakpointValue,
} from '../../../utils'
import { textCircleTransformConfig, blocksToHalvingOffset, borderWidthBreakpoints } from './circles.constants'
import { HalvingCountdown } from './HalvingCountdown'
import { HalvingCircleShadow } from './HalvingCircleShadow'

const MotionBox = chakra(motion.div, {
	shouldForwardProp: (prop) => isValidMotionProp(prop) || shouldForwardProp(prop),
})

export const BlocksToHalving = () => {
	const { reducedMotion, disableHalvingPulse } = useAppSelector(({ settings }) => settings)
	const circlesBreakpoint = useRecoilValue(circlesBreakpointState)
	const intl = useIntl()
	const circleRef = useRef<HTMLDivElement>(null)
	const circleTextRef = useRef<HTMLDivElement>(null)
	const halvingIndicatorRef = useRef<HTMLDivElement>(null)
	const [circleClientWidth, setCircleClientWidth] = useState(0)
	const [refAcquired, setRefAcquired] = useState(false)
	const [textTransform, setTextTransform] = useState<string | undefined>(undefined)
	const [circleAnimationDuration, setCircleAnimationDuration] = useState(0)
	const [halvingIntroComplete, setHalvingIntroComplete] = useRecoilState(halvingIntroCompleteState)
	const setHalvingIntroStart = useSetRecoilState(halvingIntroStartState)

	const block = useRecoilValue(blockState)
	const scrubBlock = useRecoilValue(scrubBlockState)
	const staticMode = useRecoilValue(staticModeState)
	const landscapeOrientation = useRecoilValue(landscapeOrientationState)
	const circlesWrapWidth = useRecoilValue(circlesWrapWidthState)
	const blockHeight = block.height
	const scrubBlockHeight = scrubBlock.height
	const halvingBlocks = getBlocksToHalving(blockHeight)
	const scrubHalvingBlocks = getBlocksToHalving(scrubBlockHeight)
	const showBlockRipple = useRecoilValue(showBlockRippleState)
	const [orange100] = useToken('colors', ['orange100'])

	const color = useColorModeValue('black', 'white')
	const bg = useColorModeValue('white', 'black')
	const alpha = useColorModeValue('rgba(255,255,255,0.5)', 'rgba(0,0,0,0.65)')
	const borderWidth = getBreakpointValue(borderWidthBreakpoints, circlesBreakpoint)
	const responsiveCircleOffset = getBreakpointValue(blocksToHalvingOffset, circlesBreakpoint)
	const responsivePillBorderWidth = getBreakpointValue({ base: 1, lg: 2, jumbo: 3 }, circlesBreakpoint)
	const responsivePillWidth = getBreakpointValue({ base: '56px', sm: '64px', lg: '70px', jumbo: '78px' }, circlesBreakpoint)
	const responsivePillTop = getBreakpointValue({ base: '-9px', sm: '-10px', lg: '-15px', jumbo: '-18px' }, circlesBreakpoint)
	const responsivePillLeft = getBreakpointValue({ base: '-18px', sm: '-22px', lg: '-25px', jumbo: '-29px'}, circlesBreakpoint)
	const responsiveFontSize = getBreakpointValue({ base: 'xxs', sm: 'xs', lg: '14px', jumbo: '15px' }, circlesBreakpoint)
	const responsiveFontWeight = getBreakpointValue({ base: 'bold', sm: 'semibold', lg: 'bold' }, circlesBreakpoint)
	const textCircleTransform = useMemo(
		() => getBreakpointValue(textCircleTransformConfig, circlesBreakpoint) as string | undefined,
		[circlesBreakpoint, landscapeOrientation],
	)
	const consitionalPos = landscapeOrientation ? 'block' : 'none'
	const countdownDisplay = getBreakpointValue({ base: consitionalPos, sm: 'block' }, circlesBreakpoint) as ResponsiveValue<'block' | 'none'>
	const countdownTop = getBreakpointValue({
		base: 0,
		xs: -4,
		sm: -5,
		md: -7,
		lg: -9,
		xl: -8,
		xxl: -9,
	}, circlesBreakpoint)
	
	const angle = getRotationAngle(halvingBlocks / EPOCH_BLOCKS)
	const indicatorRotatedText = angle > 100 && angle < 260
	const scrubAngle = getRotationAngle(scrubHalvingBlocks / EPOCH_BLOCKS)
	const scrubIndicatorRotatedText = scrubAngle > 90 && scrubAngle < 270
	const indicatorTextTransform = staticMode
		? scrubIndicatorRotatedText
			? 'rotate(180deg)'
			: undefined
		: indicatorRotatedText
			? 'rotate(180deg)'
			: undefined
	const rotationAngle = staticMode ? `-${scrubAngle % 360}` : `-${(angle % 360) + 360}`
	const transitionBlocks = 12000
	const shouldAnimate = halvingBlocks <= transitionBlocks
	const darkColorOpacity = shouldAnimate
		? ((halvingBlocks / transitionBlocks) * 0.9).toFixed(2)
		: 1
	const color1 = '#f7931a'
	const color2 = `rgba(247, 147, 26, ${darkColorOpacity})`
	const pulseColor = {
		borderColor: [
			color1,
			color1,
			color1,
			color2,
			color1,
		]
	}
	const pulseTransition = {
		duration: circleAnimationDuration,
		ease: 'easeInOut',
		repeat: Infinity,
		repeatType: 'loop',
	}
	const shouldPulse = !showBlockRipple
		&& !reducedMotion
		&& !disableHalvingPulse
		&& halvingIntroComplete
		&& shouldAnimate

	// INIT
	useEffect(() => {
		setRefAcquired(true)
	}, [])

	// CIRCLE SIZING
	useEffect(() => {
		const setCircleWidth = () => {
			const circle = circleRef.current
			if (circle && responsiveCircleOffset) {
				setCircleClientWidth(circle.clientWidth)
			}
		}
		setCircleWidth()
		setTextTransform(textCircleTransform)
		window.addEventListener('resize', setCircleWidth)
		return () => window.removeEventListener('resize', setCircleWidth)
	}, [
		refAcquired,
		circlesBreakpoint,
		textCircleTransform,
	])

	// HALVING PULSE
	useEffect(() => {
		const durationFloor = 0.1
		const newDuration = Number((((halvingBlocks / transitionBlocks) + durationFloor) * 2).toFixed(2))

		if (newDuration !== circleAnimationDuration) {
			setCircleAnimationDuration(newDuration)
		}
	}, [
		shouldAnimate,
		halvingBlocks,
		circleAnimationDuration,
	])

	// ANIMATE INTRO
	useLayoutEffect(() => {
		const ctx = gsap.context(() => {
			if (!halvingIntroComplete) {
				setHalvingIntroStart(true)
				gsap.from(halvingIndicatorRef.current, {
					rotation: '0_ccw',
					duration: 2,
					ease: 'easeIn',
					delay: 0.5,
					onComplete: () => {
						setHalvingIntroComplete(true)
					},
				})
				gsap.from(circleTextRef.current, {
					opacity: 0,
					duration: 1,
					ease: 'easeIn',
					delay: 0.5,
				})
			}
		})

		return () => ctx.revert()
	}, [halvingIntroComplete])

	return (
		<CircleWrap className="blocks-to-halving-circle">
			<HalvingCircleShadow circleClientWidth={circleClientWidth} />
			
			<Box display={countdownDisplay}>
				<HalvingCountdown posTop={countdownTop} />
			</Box>

			<TextPathCircle
				overrideTransform={textTransform}
				containerWidth={circlesWrapWidth}
				circleWidth={circleClientWidth}
				text={`${intl.formatMessage({ id: 'circles.blocks_to_halving' })} >`}
				id="halving"
			/>

			<MotionBox
				ref={circleRef}
				pos="absolute"
				top={responsiveCircleOffset}
				left={responsiveCircleOffset}
				right={responsiveCircleOffset}
				bottom={responsiveCircleOffset}
				bg={bg}
				borderRadius="50%"
				borderWidth={borderWidth}
				borderColor="orange100"
				animate={shouldPulse ? pulseColor : undefined}
				// @ts-ignore
				transition={shouldPulse ? pulseTransition : undefined}
			>
				<TopMark
					staticMode={staticMode}
					color="orange100"
					disableBlur
				/>

				{!halvingIntroComplete && (
					<Box
						ref={halvingIndicatorRef}
						pos="absolute"
						h="50%"
						w="20px"
						top={0}
						left={0}
						right={0}
						m="auto"
						transform={`rotateZ(${rotationAngle}deg)`}
						transformOrigin="50% 100%"
					>
						<Flex
							justifyContent="center"
							alignItems="center"
							pos="absolute"
							borderWidth={responsivePillBorderWidth}
							borderRadius="16px"
							borderColor="orange100"
							boxShadow={`3px 0px 0px ${alpha}, -3px 0px 0px ${alpha}`}
							color={color}
							bg={bg}
							p="2px 4px"
							w={responsivePillWidth}
							left={responsivePillLeft}
							top={responsivePillTop}
							zIndex="1001"
						>
							<Text
								ref={circleTextRef}
								fontSize={responsiveFontSize}
								lineHeight="none"
								fontWeight={responsiveFontWeight}
							>
								{staticMode && `${scrubHalvingBlocks}`}
								{!staticMode && `${halvingBlocks}`}
							</Text>
						</Flex>

						<CircleIndicatorArrow color={orange100} />
					</Box>
				)}

				{halvingIntroComplete && (
					<Box
						pos="absolute"
						h="50%"
						w="20px"
						top={0}
						left={0}
						right={0}
						m="auto"
						transform={`rotateZ(${rotationAngle}deg)`}
						transformOrigin="50% 100%"
					>
						<Flex
							justifyContent="center"
							alignItems="center"
							pos="absolute"
							borderWidth={responsivePillBorderWidth}
							borderRadius="16px"
							borderColor="orange100"
							boxShadow={`3px 0px 0px ${alpha}, -3px 0px 0px ${alpha}`}
							color={color}
							bg={bg}
							p="2px 4px"
							w={responsivePillWidth}
							left={responsivePillLeft}
							top={responsivePillTop}
							zIndex="1001"
						>
							<Text
								fontSize={responsiveFontSize}
								lineHeight="none"
								fontWeight={responsiveFontWeight}
								transform={indicatorTextTransform}
							>
								{staticMode && `${scrubHalvingBlocks}`}
								{!staticMode && `${halvingBlocks}`}
							</Text>
						</Flex>

						<CircleIndicatorArrow color={orange100} />
					</Box>
				)}
			</MotionBox>
		</CircleWrap>
	)
}
