import { atom } from 'recoil'

import type { CircleBreakpointsStateKey } from '../utils'

export const contentHeightState = atom({
	key: 'contentHeight',
	default: 0 as number | string,
})

export const circlesWrapWidthState = atom({
	key: 'circlesWrapWidth',
	default: 0,
})

export const circlesBreakpointState = atom({
	key: 'circlesBreakpoint',
	default: 'base' as CircleBreakpointsStateKey
})

export const landscapeOrientationState = atom({
	key: 'landscapeOrientation',
	default: true,
})
