import {
	Flex,
	Modal,
	ModalContent,
	ModalFooter,
	ModalBody,
	Button,
	useDisclosure,
	type FlexProps,
	useColorModeValue,
} from '@chakra-ui/react'
import { FormattedMessage } from 'react-intl'

import { useAppSelector } from '../../../hooks'
import { CloseButtonLabel, ModalOverlay } from '../../shared'
import { P } from './TCInfoAccordion'
import { TCInfoButton } from './TCInfoButton'
import { TCInfoAccordion } from './TCInfoAccordion'
import { BITCOIN_ORANGE } from '../../../constants'
import { rtlLocales } from '../../../lang/messages'

export const TCInfo = ({ ...rest }: FlexProps) => {
	const { isOpen, onOpen, onClose } = useDisclosure()
	// @ts-ignore
	const { userLocale } = useAppSelector(({ settings }) => settings)
	const direction = rtlLocales.includes(userLocale) ? 'rtl' : undefined
	const versionNum = process.env.REACT_APP_VERSION
	const EST_BLOCK = 762135
	const bg = useColorModeValue('white', 'black')
	const color = useColorModeValue('black', 'white')
	const colorAlt = useColorModeValue('rgba(0,0,0,0.5)', 'rgba(255,255,255,0.5)')

	return (
		<>
			<TCInfoButton onClick={onOpen} {...rest} />

			<Modal
				key="info"
				isOpen={isOpen}
				onClose={onClose}
				isCentered
				motionPreset="slideInBottom"
				size={{ base: 'sm', md: 'md' }}
			>
				<ModalOverlay />

				<ModalContent
					bg={bg}
					borderWidth={1}
					borderColor="white"
					borderRadius="lg"
					zIndex={3334}
					dir={direction}
				>
					<ModalBody mt={3}>
						<TCInfoAccordion />
					</ModalBody>

					<ModalFooter>
						<Flex
							w="100%"
							justify="space-between"
							align="flex-end"
							color={color}
						>
							<Flex direction="column" align="flex-start">
								<P
									textTransform="uppercase"
									fontWeight="bold"
									fontSize="12px"
									lineHeight="shorter"
									mt={0}
									mb="2px"
								>
									<FormattedMessage id="info.modal_footer.established" /> {EST_BLOCK}
								</P>
								<P
									display="inline-block"
									textAlign="center"
									textTransform="uppercase"
									fontWeight="semibold"
									fontSize="11px"
									color={colorAlt}
									m={0}
								>
									<FormattedMessage id="info.modal_footer.version" /> {versionNum}
								</P>
							</Flex>

							<Button
								onClick={onClose}
								variant="outline"
								colorScheme="black"
								fontSize="sm"
								textTransform="uppercase"
								_active={{
									borderColor: BITCOIN_ORANGE,
									color: BITCOIN_ORANGE,
								}}
								_hover={{
									borderColor: BITCOIN_ORANGE,
									color: BITCOIN_ORANGE,
								}}
							>
								<CloseButtonLabel />
							</Button>
						</Flex>
					</ModalFooter>
				</ModalContent>
			</Modal>
		</>
	)
}
