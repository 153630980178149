import { atom } from 'recoil'
import type { TimePickerValue } from 'react-time-picker'

import type { Block, SimpleBlock } from '../models'
import type { UserCurrencyMap } from '../components/features'

export const pastBlocksState = atom({
	key: 'pastBlocks',
	default: [] as SimpleBlock[] | Block[],
})

export const pastBlockHoverState = atom({
	key: 'pastBlockHover',
	default: null as number | null
})

export const pastBlockHoverTimestampState = atom({
	key: 'pastBlockHoverTimestamp',
	default: null as number | null
})

export const blockState = atom({
	key: 'block',
	default: {
		height: 0,
		timestamp: 0,
		tx_count: 0,
		size: 0,
		extras: {
			avgFeeRate: 0,
			totalFees: 0,
			pool: {},
		},
	} as Block,
})

export const scrubBlockState = atom({
	key: 'scrubBlock',
	default: {
		height: 0,
		timestamp: 0,
		tx_count: 0,
		size: 0,
		extras: {
			avgFeeRate: 0,
			totalFees: 0,
			pool: {},
		},
	} as Block,
})

export const blockTxsState = atom({
	key: 'blockTxs',
	default: []
})

export const poolNameState = atom({
	key: 'poolName',
	default: '',
})

export const priceState = atom({
	key: 'price',
	default: 0,
})

export const usdRatiosState = atom({
	key: 'usdRatios',
	default: {} as UserCurrencyMap,
})

export const scrubUsdRatiosState = atom({
	key: 'scrubUsdRatios',
	default: {} as UserCurrencyMap,
})

export const scrubPriceState = atom({
	key: 'scrubPrice',
	default: null as number | null,
})

export const diffBlocksVsTargetState = atom({
	key: 'diffBlocksVsTarget',
	default: 0,
})

export const futureBlockState = atom({
	key: 'futureBlock',
	default: null as number | null,
})

export const futureBlockDateState = atom({
	key: 'futureBlockDate',
	default: null as Date | null,
})

export const futureBlockTimeState = atom({
	key: 'futureBlockTime',
	default: null as TimePickerValue | null,
})

export const showBlockDropState = atom({
	key: 'showBlockDrop',
	default: false,
})

export const showBlockRippleState = atom({
	key: 'showBlockRipple',
	default: false,
})

export const editingBlockHeightState = atom({
	key: 'editingBlockHeight',
	default: false,
})

export const loadingBlockHeightState = atom({
	key: 'loadingBlockHeight',
	default: false,
})

export const shouldSetAppToBlockPathState = atom({
	key: 'shouldSetAppToBlockPath',
	default: false,
})
