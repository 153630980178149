import { type FC, type PropsWithChildren } from 'react'
import {
	Button,
	Flex,
	chakra,
	shouldForwardProp,
	useColorModeValue,
} from '@chakra-ui/react'
import { motion, isValidMotionProp } from 'framer-motion'
import { SunIcon, StarIcon } from '@chakra-ui/icons'
import { useRecoilValue } from 'recoil'

import { circlesBreakpointState, showBlockRippleState } from '../../../state'
import {
	BITCOIN_ORANGE,
	buttonBreaks,
	buttonBottom,
	blurInAnimation,
} from '../../../constants'
import { BitcoinBCircle } from '../../shared'
import { getBreakpointValue } from '../../../utils'

interface AlertButtonProps {
	onClick: () => void
	isAlertOpen: boolean
}

const MotionBox = chakra(motion.div, {
	shouldForwardProp: (prop) => isValidMotionProp(prop) || shouldForwardProp(prop),
})

export const TopAlertsWrap: FC<PropsWithChildren> = ({ children }) => {
	return (
		<Flex
			pos="relative"
			h="40px"
			minH="40px"
			w="100%"
			justify="center"
			align="flex-end"
			zIndex="11"
			overflow="hidden"
		>
			{children}
		</Flex>
	)
}

export const AnimatedSunIcon = () => {
	const sunYellow = '#fbec5d'

	return (
		<Flex
			align="center"
			justify="center"
			w={6}
			h={6}
			ml={2}
		>
			<MotionBox
				display="flex"
				align="center"
				justify="center"
				transformOrigin="center"
				animate={{
					rotate: ['0deg', '360deg'],
					scale: [1, 1.2, 1],
					color: [
						BITCOIN_ORANGE,
						sunYellow,
						BITCOIN_ORANGE,
						sunYellow,
						BITCOIN_ORANGE,
						sunYellow,
						BITCOIN_ORANGE
					],
				}}
				// @ts-ignore
				transition={{
					duration: 5,
					ease: 'linear',
					repeat: Infinity,
					repeatType: 'loop',
				}}
			>
				<SunIcon color="currentColor" />
			</MotionBox>				
		</Flex>
	)
}

export const AnimatedBitcoinB = () => {
	return (
		<MotionBox
			color={BITCOIN_ORANGE}
			display="flex"
			align="center"
			animate={{
				color: [
					BITCOIN_ORANGE,
					'#000000',
					BITCOIN_ORANGE,
				]
			}}
			// @ts-ignore
			transition={{
				duration: 2,
				ease: 'linear',
				repeat: Infinity,
				repeatType: 'loop',
			}}
		>
			<BitcoinBCircle width="20px" color="currentColor" />
		</MotionBox>
	)
}

export const CalendarAlertButton = ({ onClick, isAlertOpen }: AlertButtonProps) => {
	const circlesBreakpoint = useRecoilValue(circlesBreakpointState)
	const showBlockRipple = useRecoilValue(showBlockRippleState)

	const bg = useColorModeValue('white', 'black')
	const colorAlt = useColorModeValue('rgba(0,0,0,0.7)', 'rgba(255,255,255,0.7)')
	const responsiveButtonHeight = getBreakpointValue(buttonBreaks, circlesBreakpoint)
	const responsiveBottom = getBreakpointValue(buttonBottom, circlesBreakpoint)
	const starDimension = getBreakpointValue({ base: '16px', md: '20px' }, circlesBreakpoint)

	const responsiveColor = isAlertOpen ? 'whiteAlpha.500' : BITCOIN_ORANGE
	const opacity = showBlockRipple ? 0.21 : 1
	const filter = showBlockRipple ? 'blur(4px)' : 'blur(0)'
	const interactionStyle = {
		borderColor: BITCOIN_ORANGE,
		color: BITCOIN_ORANGE,
	}

	return (
		<Button
			className="tc-calendar-alert-button"
			role="group"
			onClick={onClick}
			pos="absolute"
			zIndex={1200}
			right={0}
			bottom={responsiveBottom}
			variant="outline"
			w={responsiveButtonHeight}
			minW={responsiveButtonHeight}
			h={responsiveButtonHeight}
			bg={bg}
			textTransform="uppercase"
			borderColor={colorAlt}
			borderWidth={2}
			borderRadius="50%"
			p={0}
			opacity={opacity}
			filter={filter}
			animation={blurInAnimation}
			color={responsiveColor}
			cursor="pointer"
			_active={interactionStyle}
			_hover={interactionStyle}
		>
			<MotionBox
				display="flex"
				flexDirection="column"
				justifyContent="center"
				animate={{
					opacity: [1, 0.3],
				}}
				// @ts-ignore
				transition={{
					duration: 0.9,
					ease: 'linear',
					repeat: Infinity,
					repeatType: 'loop',
				}}
			>
				<StarIcon boxSize={starDimension} />
			</MotionBox>
		</Button>
	)
}
