import { useState, useEffect } from 'react'
import { Flex, useColorModeValue } from '@chakra-ui/react'
import { useRecoilValue } from 'recoil'
import { differenceInSeconds } from 'date-fns'

import { AsicGrill } from './AsicGrill'

import { appInitStartTimeState } from '../../../state'
import { getAvgBlockTime } from '../../../utils'
import { Label } from '../../shared'



export const Loading = () => {
	const appInitStartTime = useRecoilValue(appInitStartTimeState)
	const [theTime, setTheTime] = useState(new Date())
	const colorAlt = useColorModeValue('rgba(0,0,0,0.7)', 'rgba(255,255,255,0.7)')
	
	const appStartTime = appInitStartTime
		? Number((Number(differenceInSeconds(theTime, appInitStartTime))).toFixed(0))
		: 0
	const timeSinceLastBlock = getAvgBlockTime(appStartTime * 1000)

	const handleReload = () => {
		window.location.reload()
	}

	useEffect(() => {
		const timer = setInterval(() => {
			setTheTime(new Date())
		}, 1000)

		return () => {
			clearInterval(timer)
		}
	}, [])

	return (
		<Flex
			direction="column"
			pos="relative"
			w="100%"
			h="100%"
			justify="center"
			align="center"
			zIndex={0}
			gap={8}
		>
			<Flex h="32px" align="center">
				{appStartTime > 9 && (
					<Label
						fontSize="16px"
						fontWeight="bold"
						lineHeight="none"
						textTransform="none"
					>
						{timeSinceLastBlock} <span style={{ color: colorAlt, fontWeight: 'normal' }}>since connection request</span>
					</Label>
				)}
			</Flex>

			<AsicGrill />

			<Flex h="32px" align="center">
				{appStartTime > 29 && (
					<Label
						onClick={handleReload}
						fontSize="16px"
						fontWeight="bold"
						lineHeight="none"
						color="orange100"
						cursor="pointer"
					>
						RELOAD?
					</Label>
				)}
			</Flex>
		</Flex>
	)
}