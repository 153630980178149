import { atom } from 'recoil'

export const estimatedRetargetDateState = atom({
	key: 'estimatedRetargetDate',
	default: 0,
})

export const difficultyChangeState = atom({
	key: 'difficultyChange',
	default: 0,
})

export const remainingBlocksState = atom({
	key: 'remainingBlocks',
	default: 0,
})

export const previousRetargetState = atom({
	key: 'previousRetarget',
	default: 0,
})

export const previousTimeState = atom({
	key: 'previousTime',
	default: 0,
})

export const timeAvgState = atom({
	key: 'timeAvg',
	default: 0,
})

export const currentDifficultyState = atom({
	key: 'currentDifficulty',
	default: 0,
})

export const prevDiffDateState = atom({
	key: 'prevDiffDate',
	default: null,
})
