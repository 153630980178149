import {
	Button,
	chakra,
	shouldForwardProp,
	Text,
	useColorMode,
	useColorModeValue,
	useToken,
} from '@chakra-ui/react'
import { motion, isValidMotionProp } from 'framer-motion'
import { useRecoilValue } from 'recoil'

import {
	circlesBreakpointState,
	scrubbingState,
	showBlockRippleState,
} from '../../../state'
import {
	buttonBreaks,
	buttonBottom,
	blurInAnimation,
} from '../../../constants'
import { BITCOIN_ORANGE } from '../../../constants'
import { getBreakpointValue } from '../../../utils'

interface GMAlertsButtonProps {
	onOpen: () => void
}

const MotionBox = chakra(motion.div, {
	shouldForwardProp: (prop) => isValidMotionProp(prop) || shouldForwardProp(prop),
})

export const GMAlertsButton = ({ onOpen }: GMAlertsButtonProps) => {
	const { colorMode } = useColorMode()
	const [orange100, darkorange60] = useToken('colors', ['orange100', 'darkorange60'])
	const circlesBreakpoint = useRecoilValue(circlesBreakpointState)
	const showBlockRipple = useRecoilValue(showBlockRippleState)
	const scrubbing = useRecoilValue(scrubbingState)

	const gmFontSize = getBreakpointValue({
		base: '11px',
		xs: '12px',
		sm: '13px',
		md: '15px',
		lg: 'md',
		xl: '19px',
		xxl: '22px',
		xxxl: '24px',
	}, circlesBreakpoint)
	const responsiveButtonHeight = getBreakpointValue(buttonBreaks, circlesBreakpoint)
	const responsiveBottom = getBreakpointValue(buttonBottom, circlesBreakpoint)
	const bg = useColorModeValue('white', 'black')
	const borderColor = useColorModeValue(BITCOIN_ORANGE, 'whiteAlpha.700')
	
	const opacity = scrubbing || showBlockRipple ? 0.21 : 1
	const filter = scrubbing || showBlockRipple ? 'blur(4px)' : 'blur(0)'
	const interactionStyle = {
		borderColor: BITCOIN_ORANGE,
		color: BITCOIN_ORANGE,
	}
	const sunYellow = '#fbec5d'
	const colorArray = colorMode === 'light'
		? [
			darkorange60,
			orange100,
			darkorange60,
			orange100,
			darkorange60,
			orange100,
			darkorange60,
		]
		: [
			BITCOIN_ORANGE,
			sunYellow,
			BITCOIN_ORANGE,
			sunYellow,
			BITCOIN_ORANGE,
			sunYellow,
			BITCOIN_ORANGE
		]

	return (
		<Button
			className="tc-gm-button"
			role="group"
			onClick={onOpen}
			pos="absolute"
			zIndex={1200}
			left={0}
			bottom={responsiveBottom}
			variant="outline"
			w={responsiveButtonHeight}
			minW={responsiveButtonHeight}
			h={responsiveButtonHeight}
			bg={bg}
			textTransform="uppercase"
			borderColor={borderColor}
			borderWidth={2}
			borderRadius="50%"
			opacity={opacity}
			filter={filter}
			transition="all 0.75s easeIn"
			p={0}
			animation={blurInAnimation}
			_active={interactionStyle}
			_hover={interactionStyle}
		>
			<MotionBox
				display="flex"
				align="center"
				justify="center"
				transformOrigin="center"
				animate={{
					color: colorArray,
				}}
				// @ts-ignore
				transition={{
					duration: 5,
					ease: 'linear',
					repeat: Infinity,
					repeatType: 'loop',
				}}
			>
				<Text
					fontSize={gmFontSize}
				>
					GM
				</Text>
			</MotionBox>
		</Button>
	)
}
