import {
	Text,
	useColorModeValue,
	type ResponsiveValue,
} from '@chakra-ui/react'
import { FormattedMessage } from 'react-intl'
import { useRecoilValue } from 'recoil'

import { circlesBreakpointState } from '../../../state'
import { getBreakpointValue } from '../../../utils'

export const TCTitle = () => {
	const circlesBreakpoint = useRecoilValue(circlesBreakpointState)
	const color = useColorModeValue('black', 'white')

	const responsiveDisplay = getBreakpointValue({ base: 'inline-block', xxs: 'block' }, circlesBreakpoint) as ResponsiveValue<'inline-block' | 'block'>
	const responsiveFontSize = getBreakpointValue({
		base: '8px',
		xxs: 'xxs',
		xs: '12px',
		sm: '14px',
		md: '15px',
		lg: '17px',
		xl: '18px',
		xxl: '19px',
		xxxl: '20px',
		jumbo: '24px',
	}, circlesBreakpoint)

	return (
		<Text
			className="tc-title"
			display={responsiveDisplay}
			color={color}
			fontSize={responsiveFontSize}
			textTransform="uppercase"
			fontWeight="bold"
			lineHeight="none"
			textAlign="center"
		>
			<FormattedMessage id="app.title.timechain_calendar" />
		</Text>
	)
}
