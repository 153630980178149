import { useLayoutEffect, useRef } from 'react'
import { Box, useColorMode } from '@chakra-ui/react'
import { gsap } from 'gsap'

export const GenesisInscriptionV3 = () => {
	const genesisInscriptionRef = useRef<HTMLDivElement>(null)
	const { colorMode } = useColorMode()
	const imageUrl = colorMode === 'light'
		? 'url(/images/genesis-white-470.png)'
		: 'url(/images/genesis-bw-470.png)'

	useLayoutEffect(() => {
		const ctx = gsap.context(() => {
			const tl = gsap.timeline()
			tl.to(genesisInscriptionRef.current, {
				opacity: 0.275,
				scale: 1.21,
				rotation: '0',
				filter: 'blur(0px)',
				duration: 10,
				ease: 'easeIn',
			})
			tl.to(genesisInscriptionRef.current, {
				opacity: 0,
				scale: 3,
				filter: 'blur(4px)',
				duration: 8,
				delay: 2,
				ease: 'easeOut',
			}, '>')
		})

		return () => ctx.revert()
	}, [])

	return (
		<Box
			pos="absolute"
			zIndex={1000}
			top="6%"
			left="6%"
			right="6%"
			bottom="6%"
			borderRadius="50%"
			overflow="hidden"
		>
			<Box
				ref={genesisInscriptionRef}
				pos="absolute"
				top={0}
				left={0}
				backgroundImage={imageUrl}
				backgroundPosition="center center"
				backgroundSize="cover"
				w="100%"
				h="100%"
				opacity={0}
				filter="blur(3px)"
				transform="scale(0.21) rotate(-40deg)"
			/>
		</Box>
	)
}